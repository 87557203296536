import React from 'react';
import LoaderPlace from '../../assets/Icons/loader-place.svg';
import '../../assets/scss/general.scss';

export default function Loader() {
  return (
    <div className="loading">
      <span><img src={LoaderPlace} alt="Logo" /></span>
      <span><img src={LoaderPlace} alt="Logo" /></span>
      <span><img src={LoaderPlace} alt="Logo" /></span>
      <span><img src={LoaderPlace} alt="Logo" /></span>
      <span><img src={LoaderPlace} alt="Logo" /></span>
    </div>
  )
}
