import axios from 'axios';
import { configs } from '../../constants/Configs';
import {  getTbAccessToken } from '../session';

export const TB_API = () => {
  return axios.create({
    baseURL: '',
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getTbAccessToken(),
    },
  });
}

export const CALENDLY_API = () => {
  return axios.create({
    baseURL: '',
    timeout: 30000,
    headers: { 'Authorization': 'Bearer ' + configs.calendlyToken }
  });
}

export const PUBLIC_API = () => {
  return axios.create({
    baseURL: '',
    timeout: 30000,
  });
}