export default function tbAuth(
    initialState = {
      loggedIn: false,
    },
    action
  ) {
    switch (action.type) {
      case 'TB_AUTH_LOGIN_SUCCESS':
        return {
          ...initialState,
          loggedIn: true,
        };
  
      case 'TB_AUTH_LOGIN_FAILED':
        return {
          ...initialState,
          loggedIn: false,
        };
  
      case 'TB_AUTH_RESET':
        return {
          ...initialState,
          loggedIn: false,
        };
  
      default:
        return initialState;
    }
  }
  