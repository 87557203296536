import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import HomeTab from '../HomeTab';
import HeaderRight from '../../components/HeaderRight';
import NavBar from '../../components/NavBar';
import HeaderNavigation from '../../components/HeaderNavigation';
import WelcomeModal from '../../components/WelcomeModal';
import Loader from '../../../components/Loading';
import Logo from '../../../assets/Icons/place-logo-nav-2.svg';
import ForYouTab from '../ForYouTab';
import { getForyou, getProjectTabData, getProptechs, getWatchlist } from '../../utils/middleware/Dashboard';
import { Actions } from '../../../store/actions';
import { Store } from '../../../store/configureStore';
import WatchlistTab from '../WatchListTab';
import { setBadgeVisibility } from '../../utils/middleware/Dashboard/notifications';
import './dashboard.scss';
import ProjectTab from '../ProjectTab';

// tb dashboard
export default function Dashboard(props) {
  // State
  const [showNavigation, setShowNavigation] = useState(true);

  // Store
  const { tbProptechs, tbWatchList, tbWatchListPage, tbWatchListPageSize, showModal, filter, orderBy, order, page, size, activeTabId, isLoading } = useSelector(state => state.tbProptechs);
  const { tbForYou, forYouPage, forYouPageSize } = useSelector(state => state.tbForYou);

  // Handle vertical tab panel
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>{children}</div>
        )}
      </div>
    );
  }

  function mobilemenu() {
    document.body.classList.toggle('mobile-menu-open');
  }

  const handleGetProptechs = async () => {
    setShowNavigation(true)
    Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: true });
    try {
      await getProptechs(filter, orderBy, order, page + 1, size);
      document.getElementById("proptechList")?.scrollTo(0, 0)
      Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: false });
    } catch (err) {
      Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: false });
    }
  }

  // set Active Tab Data 
  useEffect(() => {
    Store.dispatch({ type: Actions.TBProptechDetails.TBProptechDetailsReset });
    setActiveTabData(activeTabId)
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeTab = (v) => {
    Store.dispatch({ type: Actions.TBProptechs.TBSetActiveTab, payload: v });
    setActiveTabData(v)
  }

  // Get proptech list on for you and watchlish page+
  useEffect(() => {
    if (tbForYou && activeTabId === 1) {
      getForyou(filter, orderBy, order, forYouPage + 1, forYouPageSize)
    } else if (activeTabId === 2) {
      getWatchlist(tbWatchListPage + 1, tbWatchListPageSize)
    }
  }, [forYouPage, forYouPageSize, tbWatchListPage, tbWatchListPageSize]) // eslint-disable-line react-hooks/exhaustive-deps

  // Get proptech list on home page
  useEffect(() => {
    if(activeTabId === 0) {
      handleGetProptechs()
    }
  }, [page, size, activeTabId]) // eslint-disable-line react-hooks/exhaustive-deps

  // Handle tab changing
  function setActiveTabData(tab) {
    Store.dispatch({ type: Actions.TBProptechs.TBResetList });
    switch (tab) {
      case 0:
        Store.dispatch({ type: Actions.TBUser.SetActiveMainTab, payload: tab + 1 });
        setBadgeVisibility();
        handleGetProptechs()
        break;

      case 1: 
        Store.dispatch({ type: Actions.TBUser.SetActiveMainTab, payload: tab + 1 });  
        getForyou(filter, orderBy, order, forYouPage + 1, forYouPageSize);
        setShowNavigation(true);
        break;

      case 2: 
        getWatchlist(filter, orderBy, order, tbWatchListPage + 1, tbWatchListPageSize)
        setShowNavigation(true)
        break;

      case 3:
        getProjectTabData()
        setShowNavigation(false)
        break;

      default: handleGetProptechs()
        break;
    }
  }

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="h-screen w-screen flex dashboard-wrapper">
        {/* Left vertical navbar */}
        <div className="left-col h-full relative flex flex-col justify-between">
          <div className="z-20 left-wrapper bg-[#111D43]">
            <div className="left-inner h-full">
              <div className="logo-left">
                <img src={Logo} alt="Place by the PTC" className='cursor-pointer' onClick={() => window.location.reload(false)} />
              </div>
              <NavBar value={activeTabId} setValue={(v) => handleChangeTab(v)} />
            </div>
          </div>
        </div>
        <div id='place-dashboard' className={`grow ${activeTabId === 3 ? "bg-[#F7F7F7]" : "bg-[#E5E5E5]"} right-col sm:overflow-hidden`}>
          <div id="place-header-1" className="pr-4 lg:pr-6 xl:pr-12 ">
            <div className="hamburger-menu z-20 lg:hidden" onClick={mobilemenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            {/* Top navigation block */}
            <div className={`navigation-top-place z-10 dashboard-navigation-place ${activeTabId === 3 && "bg-[#F7F7F7]"} flex flex-col`}>
              <div className='right-nav-place'>
                <HeaderRight activeTabId={activeTabId} />
              </div>
            </div>
          </div>
          <div id="proptechList" className={`${activeTabId === 3 ? "" : "px-4 lg:px-6 xl:px-12"} sm:h-[calc(100%-70px)] sm:overflow-y-auto overflow-x-hidden`}>
            {activeTabId !== 3 ?
              <div className='sm:sticky sm:top-0 sm:z-[12] w-full flex justify-between bg-[#E5E5E5] navigation-outer-header-place'>
                <div className='py-[15px] font-semibold opacity-40 proptech-count-wrapper'>
                  {activeTabId !== 3 ?
                    <span className="pb-1 proptech-count">
                      {activeTabId === 0 && tbProptechs?.count ? tbProptechs?.count :
                        activeTabId === 1 && tbForYou?.count ? tbForYou?.count :
                          activeTabId === 2 && tbWatchList?.count ? tbWatchList?.count :
                            0} Proptechs
                    </span>
                    : <span className="pb-1 proptech-count"></span>}
                </div>
                {showNavigation &&
                  <HeaderNavigation tab={activeTabId} />
                }
              </div>
              : null
            }
            <div className='tb-dashboard-right-wrapper sm:pt-48'>
              <TabPanel value={activeTabId} index={0}>
                <HomeTab isLoading={isLoading} tab={activeTabId} />
              </TabPanel>
              <TabPanel value={activeTabId} index={1}>
                <ForYouTab isLoading={isLoading} tab={activeTabId} />
              </TabPanel>
              <TabPanel value={activeTabId} index={2}>
                <WatchlistTab tab={activeTabId} />
              </TabPanel>
              <TabPanel value={activeTabId} index={3}>
                <ProjectTab tab={activeTabId} />
              </TabPanel>
            </div>
          </div>
          {showModal ? <WelcomeModal /> : null}
        </div>
      </div>
    </>
  )
}
