import {configs} from '../Configs';

// ****** Base URL ****** //
export const BASE_URL = configs.api;

// ******  Place / Tech Buyer (TB) ****** //
export const TB_SIGNUP_API = `${BASE_URL}/tb/auth/signup`;
export const TB_FORGOT_PASSWORD_API = `${BASE_URL}/tb/auth/forgotPassword`;
export const TB_RESET_PASSWORD_API = `${BASE_URL}/tb/auth/resetPassword`;
export const TB_CHANGE_PASSWORD_API = `${BASE_URL}/tb/auth/changePassword`;
export const TB_SEND_EMAIL_VERIFICATION = `${BASE_URL}/tb/auth/re-send-email-invitation`;
export const TB_PROPTECH_LISTING_HOME = `${BASE_URL}/tb/proptechs/home`;
export const TB_LOGIN_API = `${BASE_URL}/tb/auth/login`;
export const TB_PLAN_DETAILS_API = `${BASE_URL}/tb/plans/refreshPlanDetails`;
export const TB_FEATURELIST = `${BASE_URL}/tb/proptechs/featured`;
export const TB_NOTIFICATIONS_API = `${BASE_URL}/tb/notifications`;
export const TB_FORYOU = `${BASE_URL}/tb/proptechs/foryou`;
export const TB_WATCHLIST = `${BASE_URL}/tb/proptechs/favorites`;
export const TB_PROPTECH_LISTING = `${BASE_URL}/tb/proptechs`;
export const TB_ACTIVEUSERSLIST_API = `${BASE_URL}/tb/auth/activeUsers`;
export const TB_USERS_API = `${BASE_URL}/tb/auth/users`;
export const TB_INVITE_API = `${BASE_URL}/tb/auth/invite`;
export const TB_MASTER_API = `${BASE_URL}/tb/master`;
export const TB_REGIONS_API = `${TB_MASTER_API}/regions`;
export const TB_REVENUERANGES_API = `${TB_MASTER_API}/revenueRanges`;
export const TB_INVESTMENT_ROUNDS_API = `${TB_MASTER_API}/investmentRounds`;
export const TB_INVESTMENT_STAGES_API = `${TB_MASTER_API}/investmentStages`;
export const TB_KEYBIZMODELS_API = `${TB_MASTER_API}/keyBizModels`;
export const TB_COMPANYPROFILE_API = `${BASE_URL}/tb/auth/companyProfile`;
export const TB_MEETINGSBASE_API = `${BASE_URL}/tb/meetings`;
export const TB_SCHEDULEMEETING_API = `${BASE_URL}/tb/meetings/schedule`;
export const TB_UPCOMINGMEETING_API = `${BASE_URL}/tb/meetings/upcoming`;
export const TB_PREVIOUSMEETING_API = `${BASE_URL}/tb/meetings/previous`;
export const TB_PLANS_API = `${BASE_URL}/tb/master/plans`;
export const TB_PLAN_REQUEST = `${BASE_URL}/tb/plans/choosePlan`;
export const TB_PROJECTS = `${BASE_URL}/tb/projects`;

/** Public apis */
export const TB_VALIDATE_LINK = `${TB_PROPTECH_LISTING}/validateLink`;
export const TB_VALIDATE_PASSWORD = `${TB_PROPTECH_LISTING}/validatePassword`;
