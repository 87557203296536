// Reducers
import loader from './reducers/loader';
import snackbar from './reducers/snackbar';
import tbAuth from './reducers/tbAuth';
import tbUser from './reducers/tbUser';
import tbProptechs from './reducers/tbProptechs';
import tbForYou from './reducers/tbForYou';
import tbProptechDetails from './reducers/tbProptechDetails';
import publicProptechUser from './reducers/publicProptechUser';

// Utility Packages
import { combineReducers } from 'redux';

/* *********************** */
/* ***** Imports End ***** */
/* *********************** */

export default combineReducers({
  loader,
  snackbar,
  tbAuth,
  tbUser,
  tbProptechs,
  tbForYou,
  tbProptechDetails,
  publicProptechUser
});
