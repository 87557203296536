import React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from '../../../store/actions';
import { dispatch } from '../../../utils/utility/store';
import Pagination from '../../components/Pagination';
import ProptechCard from '../../components/ProtechCard';

export default function ForYouTab({ tab }) {
  const { tbForYou, forYouPage, forYouPageSize } = useSelector(state => state.tbForYou);

  // Set page no. in store on page change in pagination
  const handlePageChange = (v) => {
    dispatch(Actions.TBForYou.TBSetPage, v)
  }

  // Set page no. and size in store on page change in pagination
  const handleRowChange = (v) => {
    dispatch(Actions.TBForYou.TBSetPageSize, v)
    dispatch(Actions.TBForYou.TBSetPage, 0)
  }

  return (
    <>
      <div className="no-print proptech-card-wrapper">
        {/* Proptech card */}
        {
          tbForYou?.rows && tbForYou?.rows.length ? tbForYou?.rows.map((proptech) => {
            return (<ProptechCard className="for-you-card" proptech={proptech} key={proptech?.id} tab={tab} />)
          }) : null
        }
      </div>
      {/* Pagination */}
      <div className='flex justify-between items-center'>
        {tbForYou?.count ? <span className='text-sm font-normal'>All values in USD</span> : null}
        {tbForYou?.count ?
          <Pagination
            length={tbForYou?.count}
            tab="Proptechs"
            page={forYouPage}
            size={forYouPageSize}
            handlePageChange={(v) => handlePageChange(v)}
            handleRowChange={(v) => handleRowChange(v)}
          />
          : null}
      </div>
    </>
  )
}