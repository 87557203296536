export function proptechDetailsDTO(data) {
  return {
    id: data?.id || 0,
    companyLogo: data?.companyLogo || '',
    publishedAt: data?.publishedAt || '',
    createdAt: data?.createdAt || '',
    proptechCompany: {
      id: data?.proptechCompany?.id || 0,
      name: data?.proptechCompany?.name || '-',
    },
    region: {
      id: data?.region?.id || 0,
      name: data?.region?.name || '-',
    },
    revenueRange: {
      id: data?.revenueRange?.id || 0,
      name: data?.revenueRange?.name || '-',
    },
    investmentRound: {
      id: data?.investmentRound?.id || 0,
      name: data?.investmentRound?.name || '-',
    },
    investmentStage: {
      id: data?.investmentStage?.id || 0,
      name: data?.investmentStage?.name || '-',
    },
    watchingCount: data?.watchingCount || 0,
    tbProptechFavorite: data?.tbProptechFavorite || [],
    tbProptechNote: data?.tbProptechNote || [],
    calendlyURL: data?.calendlyURL || "",
  }
}
