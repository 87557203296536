import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProptechCard from '../../components/ProtechCard';
import { Store } from '../../../store/configureStore';
import { Actions } from '../../../store/actions';
import Pagination from '../../components/Pagination';
import { dispatch } from '../../../utils/utility/store';

export default function WatchlistTab({ tab }) {
  // Store
  const { tbWatchList, tbWatchListPage, tbWatchListPageSize } = useSelector(state => state.tbProptechs);
  
  useEffect(() => {
    Store.dispatch({ type: Actions.TBProptechDetails.TBProptechDetailsReset });
  }, [])

  const handlePageChange = (v) => {
    dispatch(Actions.TBProptechs.TBWatchlistPageSet, v)
    // setHomeCount(1)
  }

  const handleRowChange = (v) => {
    dispatch(Actions.TBProptechs.TBWatchlistPageSizeSet, v)
    dispatch(Actions.TBProptechs.TBWatchlistPageSet, 0)
    // setHomeCount(1)
  }


  return (
    <>
      <div className="no-print proptech-card-wrapper watchlist-card">
        {
          tbWatchList?.rows && tbWatchList?.rows.length ? tbWatchList?.rows.map((proptech) => {
            return (<ProptechCard proptech={proptech} key={proptech?.id} tab={tab} />)
          }) : null
        }
      </div>
      <div className='flex justify-between items-center'>
        {tbWatchList?.count ? <span className='text-sm font-normal'>All values in USD</span> : null}
        {tbWatchList?.count ?
          <Pagination
            length={tbWatchList?.count}
            tab="Proptechs"
            page={tbWatchListPage}
            size={tbWatchListPageSize}
            handlePageChange={(v) => handlePageChange(v)}
            handleRowChange={(v) => handleRowChange(v)}
          />
          : null}
      </div>
    </>
  )
}