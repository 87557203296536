//tb session

export const setTbAccessToken = (data) => {
    localStorage.setItem('tbAccessToken', JSON.stringify(data));
};

export const getTbAccessToken = () => {
    return JSON.parse(localStorage.getItem('tbAccessToken'));
};

export const setRememberIdTB = (data) => {
    localStorage.setItem('tbEmailToRemember', JSON.stringify(data));
}

export const getRememberIdTB = () => {
    return JSON.parse(localStorage.getItem('tbEmailToRemember'));
}

export const getTbLocalStorageUserData = () => {
    return JSON.parse(localStorage.getItem('tbUserData'));
};

export const setTbLocalStorageUserData = (data) => {
    localStorage.setItem('tbUserData', JSON.stringify(data));
};

export const removeTbSessionToken = () => {
    localStorage.removeItem('tbUserData');
    localStorage.removeItem('tbAccessToken');
    window.location.href = '/login';
};