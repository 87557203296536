import { TB_NOTIFICATIONS_API } from "../../../../constants/APIs"
import { Actions } from "../../../../store/actions"
import { TB_API } from "../../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../../utils/utility/snackbar"
import { dispatch } from "../../../../utils/utility/store"
import { notificationsListDTO } from "../../dtos/notifications"

export const setBadgeVisibility = () => {
  TB_API().get(TB_NOTIFICATIONS_API + '/checkUnread').then(res => {
    handleSuccess(res)
    dispatch(Actions.TBUser.SetNotificationCheckUnread, res.data.data)
  }).catch((error) => handleError(error))
}

export const getNotifications = () => {
  dispatch(Actions.TBUser.setNotificationLoading, true)
  TB_API().get(TB_NOTIFICATIONS_API).then(res => {
    handleSuccess(res)
    dispatch(Actions.TBUser.SetNotifications, notificationsListDTO(res.data.data.rows))
    dispatch(Actions.TBUser.SetNotificationCheckUnread, false)
    dispatch(Actions.TBUser.setNotificationLoading, false)
  }).catch((error) => {
    dispatch(Actions.TBUser.setNotificationLoading, false)
    handleError(error)
  })
}

export const readNotification = (tbNotificationId) => {
  TB_API().post(TB_NOTIFICATIONS_API, {
    tbNotificationId
  }).then(res => {
    // handleSuccess(res)
    getNotifications()
  }).catch((error) => handleError(error))
}

export const clearNotification = () => {
  TB_API().post(`${TB_NOTIFICATIONS_API}/clearAllNotification`)
    .then(res => {
      dispatch(Actions.TBUser.SetNotifications, [])
      handleSuccess(res)
    }).catch((error) => handleError(error))
}