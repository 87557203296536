import React from "react";
import { Chart, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@mui/material";
Chart.register(...registerables);

const createGradient = (colors) => {
  const ctx = document.createElement("canvas").getContext("2d");
  const gradient = ctx.createLinearGradient(0, 0, 0, 200);

  colors.forEach((color, index) => {
    gradient.addColorStop(index / (colors.length - 1), color);
  });

  return gradient;
};

const generateStackedOutput = (input, maximum) => {
  const output = [];
  const max = maximum;

  for (let i = 0; i < max; i++) {
    const row = input.map((value) => (value >= max - i ? max : 0));
    output.push(row);
  }

  return output;
};

//company profile bar chart
const StackedBarChart = ({ labels, datasets, chartData, maximum = 5 }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

  let newDatasets = generateStackedOutput(datasets, maximum);
  const formattedLabels = labels.map(
    (label, index) => `${index + 1}. ${label}`
  );

  const data = {
    labels: formattedLabels,
    datasets: chartData?.map((item, idx) => {
      return {
        label: item.name,
        data: newDatasets[idx],
        backgroundColor: createGradient([
          "#009BE2",
          "#37B2EA",
          "#7CCBEF",
          "#A5D7EE",
          "#BEE3F3",
        ]),
        borderColor: "rgba(255, 255, 255, 1)",
        borderWidth: 1,
        barThickness: isSmallScreen ? 30 :  56,
      };
    }),
  };

  // Chart options
  const options = {
    elements: {
      bar: {
        borderWidth: 0,
        borderRadius: 10,
        borderSkipped: "bottom",
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins, sans-serif",
            weight: "bold",
            size: 10,
          },
          color: "black",
        },
      },
      y: {
        stacked: true,
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = "";
            if (context.parsed.y !== null) {
              label = datasets[context.parsed.x];
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <div className={`max-h-60 h-full w-full ${isLargeScreen && "max-h-[400px]"}`}>
      <Bar
        data={data}
        options={options}
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
};

export default StackedBarChart;
