export default function tbUser(
    initialState = {
      user: null,
      notificationCheckUnread: false,
      notifications: [],
      notificationLoading: false,
      tbPlanDetails: null,
      tbCountDetails: null,
      changePlanModal: false,
      proptechTabsAssigned: [],
      activeMainTab: null,
    },
    action
  ) {
    switch (action.type) {
      case 'TB_USER_SET':
        return {
          ...initialState,
          user: action.payload,
        };
  
      case 'TB_USER_REMOVE':
        return {
          ...initialState,
          user: null,
        };
  
      case 'TB_NOTIFICATION_CHECK_UNREAD':
        return {
          ...initialState,
          notificationCheckUnread: action.payload,
        };
  
      case 'TB_SET_NOTIFICATIONS':
        return {
          ...initialState,
          notifications: action.payload,
        };
  
      case 'SET_NOTIFICATION_LOADING':
        return {
          ...initialState,
          notificationLoading: action.payload
        };

      case 'SET_TB_ACTIVE_PLAN':
        return {
          ...initialState,
          tbPlanDetails: action.payload
        };
  
      case 'SET_TB_COUNT_DETAILS':
        return {
          ...initialState,
          tbCountDetails: action.payload
        };
      case 'SET_TB_CHANGE_PLAN_MODAL':
        return {
          ...initialState,
          changePlanModal: action.payload,
        };
  
      case 'SET_TB_NAV_TABS':
        return {
          ...initialState,
          proptechTabsAssigned: action.payload,
        };
    
      case 'SET_ACTIVE_MAIN_TAB':
        return {
          ...initialState,
          activeMainTab: action.payload,
        };
    
      case 'TB_USER_RESET':
        return {
          ...initialState,
          user: null,
          notificationCheckUnread: false,
          notifications: [],
          notificationLoading: false,
          tbPlanDetails: null,
          tbCountDetails: null,
          changePlanModal: false,
          proptechTabsAssigned: [],
          activeMainTab: null,
        };
  
      default:
        return initialState;
    }
  }
  