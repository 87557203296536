import { TB_PROJECTS, TB_PROPTECH_LISTING } from "../../../../constants/APIs"
import { Actions } from "../../../../store/actions"
import { Store } from "../../../../store/configureStore"
import { TB_API } from "../../../../utils/utility/API-call"
import { handleError } from "../../../../utils/utility/snackbar"
import { apiTimeoutTime } from "../../../constants"
import { companyProfileTabTBDTO } from "../../dtos/companyProfileDTO"
import { deployAndUseDTO } from "../../dtos/deployAndUseTab"
import { designReviewTabTBDTO } from "../../dtos/designReviewTB"
import { marketInsightsTabTBDTO } from "../../dtos/marketInsightsTB"
import { marketTabTBDTO } from "../../dtos/marketTB"
import { numbersTabTBDTO } from "../../dtos/numbersTB"
import { overviewTabTBDTO } from "../../dtos/overviewTB"
import { proptechDetailsDTO } from "../../dtos/proptechDetailsDTO"

export const getProptechDetailsAPI = (proptechCompanyDetailsId) => {
  return TB_API().get(`${TB_PROPTECH_LISTING}/${proptechCompanyDetailsId}`).then((res) => {
    Store.dispatch({ type: Actions.TBProptechDetails.setTBProptechDetails, payload: proptechDetailsDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { proptechDetails: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { proptechDetails: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
    if (res?.data?.code !== 200) {
      handleError(res?.data);
    }
  }).catch((err) => {
    handleError(err)
    return err?.status;
  })
}

export const getOverviewAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return TB_API().get(`${TB_PROPTECH_LISTING}/${proptechCompanyDetailsId}/overview`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.TBProptechDetails.setTBOverviewDetails, payload: overviewTabTBDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { overview: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { overview: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    handleError(err)
    setIsLoading(false)
    return err?.status;
  })
}

export const getOfferingAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return TB_API().get(`${TB_PROPTECH_LISTING}/${proptechCompanyDetailsId}/market`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.TBProptechDetails.setTBMarketDetails, payload: marketTabTBDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { market: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { market: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    setIsLoading(false)
    handleError(err)
    return err?.status;
  })
}

export const getTrackRecordAPI = (proptechCompanyDetailsId, setIsLoading) => {
  getPastProjectsData(proptechCompanyDetailsId)
  return TB_API().get(`${TB_PROPTECH_LISTING}/${proptechCompanyDetailsId}/numbers`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.TBProptechDetails.setTBNumbersDetails, payload: numbersTabTBDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { numbers: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { numbers: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    setIsLoading(false)
    handleError(err)
    return err?.status;
  })
}

export const getDeployAndUseAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return TB_API().get(`${TB_PROPTECH_LISTING}/${proptechCompanyDetailsId}/deployUse`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.TBProptechDetails.setTBDeployAndUseDetails, payload: deployAndUseDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { deployAndUse: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { deployAndUse: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    setIsLoading(false)
    handleError(err)
    return err?.status;
  })
}

export const getDesignReviewAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return TB_API().get(`${TB_PROPTECH_LISTING}/${proptechCompanyDetailsId}/designReview`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.TBProptechDetails.setTBDesignReviewDetails, payload: designReviewTabTBDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { designReview: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { designReview: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    setIsLoading(false)
    handleError(err)
    return err?.status;
  })
}

export const getMarketInsightsAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return TB_API().get(`${TB_PROPTECH_LISTING}/${proptechCompanyDetailsId}/marketInsights`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.TBProptechDetails.setTBMarketInsightsDetails, payload: marketInsightsTabTBDTO(res?.data?.data?.marketInsight) });
    Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { marketInsights: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { marketInsights: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    setIsLoading(false)
    handleError(err)
    return err?.status;
  })
}

export const getCompanyProfileAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return TB_API().get(`${TB_PROPTECH_LISTING}/${proptechCompanyDetailsId}/companyProfile`).then((res) => {
    setIsLoading(false)
    Store.dispatch({ type: Actions.TBProptechDetails.setTBCompanyProfileDetails, payload: companyProfileTabTBDTO(res?.data?.data) });
    Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { companyProfile: false } });
    setTimeout(() => {
      Store.dispatch({ type: Actions.TBProptechDetails.setTBApiTimeout, payload: { companyProfile: true } });
    }, apiTimeoutTime)
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    setIsLoading(false)
    handleError(err)
    return err?.status;
  })
}

export const getKeyAttributesData = () => {
  return TB_API().get(`${TB_PROJECTS}/keyAttributes`).then((res) => {
    Store.dispatch({ type: Actions.TBProptechDetails.setTBKeyAttributesDetails, payload: (res?.data?.data) });
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    // handleError(err)
    return err?.status;
  })
}

export const getPastProjectsData = (proptechCompanyDetailsId) => {
  return TB_API().get(`${TB_PROPTECH_LISTING}/${proptechCompanyDetailsId}/pastProjects`).then((res) => {
    Store.dispatch({ type: Actions.TBProptechDetails.setTBPastProjectsDetails, payload: (res?.data?.data) });
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    handleError(err)
    return err?.status;
  })
}