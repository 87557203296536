import React from "react";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";

//project tab timeline
const CustomizedTimeline = ({ data }) => {
  return (
    <>
      <div className="md:flex w-full md:justify-start" id="timeline-container">
        <div className=" flex relative md:flex-row flex-col  items-center md:items-stretch">
          {data?.length ? (
            data?.map((item, idx) => (
              <div
                key={idx}
                className={` w-4/5  h-auto md:min-h-[450px] md:!w-[250px]`}
              >
                {idx % 2 === 0 && (
                  <>
                    <div
                      className={`h-[50%] flex relative flex-col justify-end md:border-b md:border-b-[#009BE2] `}
                    >
                      <div className="md:overflow-scroll">
                        <h2 className="!pb-0 text-sm w-full">
                          {item?.heading}
                        </h2>
                        <p
                          className="text-[10px]  timeline-details w-full md:!justify-start"
                          title={item?.value}
                        >
                          {item?.value}
                        </p>
                      </div>
                      <div className="flex items-center rotate-180 flex-col justify-center -mb-2 md:-mb-[11px]">
                        <FiberManualRecordIcon className=" text-[#009BE2] text-sm md:text-[18px]" />
                        <div className="w-[2px] h-8 rounded-full -mb-[3px] -mt-1.5 bg-[#009BE2]"></div>
                        <FiberManualRecordOutlinedIcon className="  text-[#009BE2] text-xs" />
                      </div>
                      {idx === data?.length - 1 && (
                        <ArrowRightRoundedIcon className="text-[#009BE2] text-4xl absolute   md:block hidden -bottom-[19px] -right-4" />
                      )}
                    </div>
                    <div
                      className={`h-[50%] md:border-t-2 border-t border-t-[#009BE2]`}
                    ></div>
                  </>
                )}
                {idx % 2 !== 0 && (
                  <>
                    <div
                      className={`h-[50%] border-b border-b-[#009BE2] relative`}
                    >
                      {idx === data?.length - 1 && (
                        <ArrowRightRoundedIcon className="text-[#009BE2] text-4xl absolute  -bottom-[19px] md:block hidden -right-4" />
                      )}
                    </div>
                    <div className={`h-[50%] md:border-t-2 border-t border-t-[#009BE2]`}>
                      <div className="flex items-center flex-col justify-center -mt-2 md:-mt-[11px] ">
                        <FiberManualRecordIcon className=" text-[#009BE2] text-sm md:text-[18px]" />
                        <div className="!w-[2px] h-8 rounded-full -mb-[3px] -mt-1.5 bg-[#009BE2]"></div>
                        <FiberManualRecordOutlinedIcon className="  text-[#009BE2] text-xs" />
                      </div>
                      <div className="md:overflow-scroll">
                        <h2 className="!pb-0 text-sm w-full">
                          {item?.heading}
                        </h2>
                        <p
                          className="text-[10px]  timeline-details w-full md:!justify-start"
                          title={item?.value}
                        >
                          {item?.value}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))
          ) : (
            <h2>-</h2>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomizedTimeline;
