import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { Store } from './store/configureStore';
import { theme } from './utils/theme';
import Router from './router';
import SnackBar from './components/GenericComponents/SnackBar';
import './App.css';

// Redux store
function App() {
  return (
    <Provider store={Store}>
      <ThemeProvider theme={theme}>
        <SnackBar />
        <Router />
      </ThemeProvider >
    </Provider >
  );
}

export default App;
