export const tabPanels = [
  {
    style: { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    path: { fillRule: "evenodd", clipRule: "evenodd", d: "M2 0H5C6.10457 0 7 0.89543 7 2V5C7 6.10457 6.10457 7 5 7H2C0.89543 7 0 6.10457 0 5V2C0 0.89543 0.89543 0 2 0ZM2 5H5V2H2V5ZM16 0H13C11.8954 0 11 0.89543 11 2V5C11 6.10457 11.8954 7 13 7H16C17.1046 7 18 6.10457 18 5V2C18 0.89543 17.1046 0 16 0ZM13 5H16V2H13V5ZM7 13C7 11.8954 6.10457 11 5 11H2C0.89543 11 0 11.8954 0 13V16C0 17.1046 0.89543 18 2 18H5C6.10457 18 7 17.1046 7 16V13ZM2 16H5V13H2V16ZM16 11H13C11.8954 11 11 11.8954 11 13V16C11 17.1046 11.8954 18 13 18H16C17.1046 18 18 17.1046 18 16V13C18 11.8954 17.1046 11 16 11ZM13 16H16V13H13V16Z", fill: "white" },
    panel: 'Home'
  },
  {
    style: { width: "20", height: "18", viewBox: "0 0 20 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    path: { fillRule: "evenodd", clipRule: "evenodd", d: "M5.88003 0H14.12C14.6794 0.0015781 15.2125 0.237309 15.59 0.65L19.74 5.16C19.9091 5.34595 20.0019 5.58872 20 5.84V6.14C20.0005 6.37365 19.9191 6.60009 19.77 6.78L10.77 17.64C10.5797 17.8685 10.2975 18.0005 10 18C9.70259 18.0005 9.42038 17.8685 9.23003 17.64L0.230027 6.78C0.080946 6.60009 -0.000429373 6.37365 2.71396e-05 6.14V5.84C-0.00182319 5.58872 0.0910003 5.34595 0.260027 5.16L4.41003 0.65C4.78758 0.237309 5.32069 0.0015781 5.88003 0ZM17.81 6L14.12 2H5.88003L2.19003 6L10 15.46L17.81 6Z", fill: "white" },
    panel: 'For you'
  },
  {
    style: { width: "20", height: "19", viewBox: "0 0 20 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    path: { fillRule: "evenodd", clipRule: "evenodd", d: "M9.39188 0.319989C9.47017 0.124384 9.66039 -0.00277823 9.87056 -1.09465e-05H10.1298C10.3381 -0.0044425 10.5263 0.124037 10.5986 0.319989L13.0817 6.20999L19.4542 6.71999C19.6593 6.73254 19.8346 6.87241 19.893 7.06999L19.9728 7.31999C20.0383 7.50868 19.9831 7.71839 19.8331 7.84999L14.9865 12L16.4225 18.22C16.4712 18.4188 16.3965 18.6275 16.233 18.75L16.0137 18.9C15.9284 18.967 15.8227 19.0023 15.7145 19C15.6232 19.0019 15.5332 18.9776 15.4552 18.93L10.0002 15.6L4.57513 18.93C4.49714 18.9776 4.40717 19.0019 4.31585 19C4.2076 19.0023 4.10188 18.967 4.01667 18.9L3.76736 18.75C3.60386 18.6275 3.52925 18.4188 3.57788 18.22L5.01393 12L0.177238 7.85999C0.0195079 7.72917 -0.0401977 7.51364 0.0276496 7.31999L0.137348 7.06999C0.195676 6.87241 0.371037 6.73254 0.57614 6.71999L6.9486 6.20999L9.39188 0.319989ZM16.6519 8.24999L11.8551 7.86999L10.0002 3.40999L8.14531 7.86999L3.34851 8.24999L7.00844 11.38L5.88154 16.06L10.0002 13.55L14.1089 16.06L12.992 11.38L16.6519 8.24999Z", fill: "white" },
    panel: 'Watchlist'
  },
  {
    style: { width: "18", height: "19", viewBox: "0 0 18 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    path: { fillRule: "evenodd", clipRule: "evenodd", d: "M15 2H16C17.1046 2 18 2.89543 18 4V17C18 18.1046 17.1046 19 16 19H2C0.89543 19 0 18.1046 0 17V4C0 2.89543 0.89543 2 2 2H3V0.5C3 0.223858 3.22386 0 3.5 0H4.5C4.77614 0 5 0.223858 5 0.5V2H13V0.5C13 0.223858 13.2239 0 13.5 0H14.5C14.7761 0 15 0.223858 15 0.5V2ZM2 17H16V6H2V17Z", fill: "white" },
    panel: 'Project'
  },
]