export default function tbForYou(
    initialState = {
      tbForYou: null,
      forYouPage: 0,
      forYouPageSize: 10,
    },
    action
  ) {
    switch (action.type) {
  
      case 'TB_FORYOU_LIST_SET':
        return {
          ...initialState,
          tbForYou: action.payload,
        };
  
      case 'TB_SET_PAGE_FORYOU':
        return {
          ...initialState,
          forYouPage: action.payload
        }
  
      case 'TB_SET_PAGESIZE_FORYOU':
        return {
          ...initialState,
          forYouPageSize: action.payload
        }
  
      case 'TB_SET_LOADING':
        return {
          ...initialState,
          isLoading: action.payload
        }
  
      case 'TB_RESET_LIST':
        return {
          ...initialState,
          tbForYou: null,
        }
  
      case 'TB_FORYOU_RESET':
        return {
          ...initialState,
          tbForYou: null,
          forYouPage: 0,
          forYouPageSize: 10,
        };
  
      default:
        return initialState;
    }
  }
  