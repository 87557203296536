import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PTSelect from "../../../components/GenericComponents/PTSelect";
import Loader from "../../../components/Loading";
import {
  getForyou,
  getProptechs,
  getWatchlist,
} from "../../utils/middleware/Dashboard";
import {
  getInvestmentStagesList,
  getRegionList,
} from "../../utils/middleware/Dropdowns";
import "./style.scss";
import { Store } from "../../../store/configureStore";
import { Actions } from "../../../store/actions";
import { sortByDefaultOptionPlace } from "../../constants";
import PTSearchInput from "../SearchInput";

//Component for tb dashboard Sorting and filter block
export default function HeaderNavigation({ tab }) {
  const [regionOption, setRegionOption] = useState([]);
  const [stageOption, setStageOption] = useState([]);
  const [filterOption, setFilterOption] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [previousSearchText, setPreviousSearchText] = useState("");
  const [activeSearchBar, setActiveSearchBar] = useState(false)

  // Store
  const { orderBy, order, tbMasterData, page, size  } = useSelector(
    (state) => state.tbProptechs
  );

  useEffect(() => {
    if(tab !== 0) {
      setSearchText("")
      setActiveSearchBar(false)
      setPreviousSearchText("")
    }
  }, [tab])

  //Api call For getting filter and sorting data
  useEffect(() => {
    if (!tbMasterData) {
      getMasterData();
    } else {
      setRegionOption(tbMasterData?.regionData || []);
      setStageOption(tbMasterData?.investmentStageData || []);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getMasterData = async () => {
    let regionData = await getRegionList();
    let investmentStageData = await getInvestmentStagesList();

    Store.dispatch({
      type: Actions.TBProptechs.TBSetMasterData,
      payload: {
        regionData: regionData,
        investmentStageData: investmentStageData,
      },
    });

    regionData?.length ? setRegionOption(regionData) : setRegionOption([]);
    investmentStageData?.length
      ? setStageOption(investmentStageData)
      : setStageOption([]);
  };

  // Function for handling to get filter fields
  const handlefilterData = async (v, orderByValue, orderValue, search) => {
    setFilterOption({
      ...filterOption,
      ...v,
    });
    Store.dispatch({ type: Actions.TBProptechs.TBSetPage, payload: 0 });
    Store.dispatch({ type: Actions.TBForYou.TBSetPage, payload: 0 });
    Store.dispatch({
      type: Actions.TBProptechs.TBSetFilter,
      payload: {
        ...filterOption,
        ...v,
      },
    });
    setIsLoading(true);
    try {
      if (tab === 0) {
        await getProptechs(
          {
            ...filterOption,
            ...v,
          },
          orderByValue || orderBy,
          orderValue || order,
          page || 1,
          size,
          search
        );
      } else if (tab === 1) {
        await getForyou(
          {
            ...filterOption,
            ...v,
          },
          orderByValue || orderBy,
          orderValue || order
        );
      } else if (tab === 2) {
        await getWatchlist(
          {
            ...filterOption,
            ...v,
          },
          orderByValue || orderBy,
          orderValue || order
        );
      }
    } catch {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const onClearInput = () => {
    setSearchText("");
    handlefilterData(null, null, null, "");
    setPreviousSearchText("")
    setActiveSearchBar(false)
  };

  const onSearch = (e) => {
    if (e.key === "Enter" && searchText !== previousSearchText) {
      setActiveSearchBar(true)
      handlefilterData(null, null, null, searchText);
      setPreviousSearchText(searchText?.trim());
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="navigation-header  gap-2">
        {/**search */}
        <div className="flex items-center">
          {tab === 0 && (
            <>
              <PTSearchInput
                onClearInput={onClearInput}
                onChangeSearchText={(e) =>
                  setSearchText(e.target.value?.trim() || "")
                }
                onKeyDown={onSearch}
                value={searchText}
                activeSearchBar={activeSearchBar}
              />
            </>
          )}
        </div>
        {/* Regions filter */}
        <div className={`nav-menu ${tab === 0 && "last-menu"}`}>
          <PTSelect
            handlefilterData={(v, orderBy, order) =>
              handlefilterData(v, orderBy, order)
            }
            optionValue={regionOption}
            multiple
            checked
            Isbutton
            labelValue="Regions"
            labelId="regionId"
            id="region-select"
            className="custom-select"
          />
        </div>
        {/**Stage options */}
        <PTSelect
          handlefilterData={(v, orderBy, order) =>
            handlefilterData(v, orderBy, order)
          }
          optionValue={stageOption}
          multiple
          checked
          Isbutton
          labelValue="Stage"
          labelId="investmentStageId"
          id="stage-select"
          className="custom-select"
        />
        {/* Sorting option */}
        <div className="nav-menu last-menu">
          <span className="text">Sort by: </span>
          <PTSelect
            handlefilterData={(v, orderBy, order) =>
              handlefilterData(v, orderBy, order)
            }
            optionValue={sortByDefaultOptionPlace}
            labelValue="Sort by"
            labelId="orderBy"
            id="sort-by-select"
            className="custom-select"
          />
        </div>
      </div>
    </>
  );
}
