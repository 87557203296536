import React from 'react'
import mapIconBlack from '../../../assets/Icons/ic-map-black.svg';

export default function ForYouTag({ tagsList }) {
  return (
    <div className="list-name">
      {tagsList.length && tagsList.map((tag, index) => {
        return (
          <span className="badge orange-badge" key={index}>
            <em>
              <img src={mapIconBlack} alt="Icon" />
            </em>
            {tag?.tag?.name}
          </span>
        )
      })}
    </div>
  )
}
