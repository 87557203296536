import React from 'react';
import { Tooltip } from '@mui/material';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import { useSelector } from 'react-redux';
import icDownload from '../../../../assets/Icons/ic-download.svg';
import PTButton from '../../../../components/GenericComponents/PTButton';
import { KeyFeatureLabel, KeySpanLogo, TextWithHeading, VerticalLogoWithHeading } from '../../Label';
import { MessageType } from '../../../../constants/General';
import './style.scss';
import { showMessage } from '../../../../utils/utility/snackbar';
import { CHANGES_MESSAGE } from '../../../constants/index';
import StackedBarChart from '../../BarChart';

export default function CompanyProfileTab() {
  // Store
  const { companyProfileDetails } = useSelector(state => state.tbProptechDetails)
  const tbPlanDetails = useSelector(state => state.tbUser.tbPlanDetails);

  const currentYear = (new Date()).getFullYear() % 100;

  const handleBeforePrint = () => {
    // Show the printable content when printing
    document.getElementById('place-proptech-details').classList.add('printable-content')
  };

  return (
    <>
      {
        companyProfileDetails ?
          <div className="tabpanel-wrapper-place company-profile-tab-place">
            <div className="watchlist-card">
              <div className="watchlist-data">
                <div className="left">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="16" height="16" rx="8" fill="#FFD84F" />
                    <path d="M13 6.42V6.57C13.0002 6.68683 12.9596 6.80004 12.885 6.89L8.38501 12.32C8.28983 12.4343 8.14873 12.5002 8.00001 12.5C7.8513 12.5002 7.71019 12.4343 7.61501 12.32L3.11501 6.89C3.04047 6.80004 2.99979 6.68683 3.00001 6.57V6.42C2.99909 6.29436 3.0455 6.17297 3.13001 6.08L5.20501 3.825C5.39379 3.61865 5.66035 3.50079 5.94001 3.5H10.06C10.3397 3.50079 10.6062 3.61865 10.795 3.825L12.87 6.08C12.9545 6.17297 13.0009 6.29436 13 6.42Z" fill="#FFF5CB" />
                  </svg>
                  This Proptech is curated to match your company profile
                </div>
                <div className="right">
                  <PTButton id="download-summary-btn" label='Download summary' iconBtn={icDownload} className="normal-case fill-yellow btn"
                    handleOnClickEvent={() => {
                      if (tbPlanDetails?.isHavingDownloadOnePager) {
                        handleBeforePrint()
                        window.print()
                      } else {
                        showMessage(CHANGES_MESSAGE.upgradePlan, MessageType.Error)
                      }
                    }} />
                </div>
              </div>
            </div>
            <div className='market-overview-place'>
              {/* Key themes block */}
              <div className="item-market">
                <div className="item-inner">
                  <div className='flex items-start justify-center'>
                    <h2 className='pr-5'>Key themes</h2>
                  </div>
                  <div className='item-inner-logos'>
                    <div className="flex">
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.name} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Sectors block */}
              <div className="item-market">
                <div className="item-inner">
                  <div className='flex items-start justify-center'>
                    <h2 className='pr-5'>Sectors</h2>
                  </div>
                  <div className='item-inner-logos'>
                    <div className="flex">
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisSector1?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisSector1?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisSector2?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisSector2?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisSector3?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisSector3?.name} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Asset lifecycle block */}
              <div className="item-market">
                <div className="item-inner">
                  <div className='flex items-start justify-center'>
                    <h2 className='pr-5'>Asset lifecycle</h2>
                  </div>
                  <div className='item-inner-logos'>
                    <div className="flex">
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle1?.icon} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle1?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle2?.icon} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle2?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle3?.icon} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle3?.name} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Tech offering block */}
              <div className="item-market">
                <div className="item-inner">
                  <div className='flex items-start justify-center'>
                    <h2 className='pr-5'>Tech offering</h2>
                  </div>
                  <div className='item-inner-logos'>
                    <div className="flex">
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisTechOffering1?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisTechOffering1?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisTechOffering2?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisTechOffering2?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisTechOffering3?.icon} text={companyProfileDetails?.proptechAnalysisOverview?.proptechAnalysisTechOffering3?.name} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Pricing block */}
              <div className="item-market">
                <div className="item-inner">
                  <div className='flex items-start justify-center'>
                    <h2 className='pr-5'>Pricing</h2>
                  </div>
                  <div className='item-inner-logos'>
                    <div className="flex">
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisChargingModel1?.icon} text={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisChargingModel1?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisChargingModel2?.icon} text={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisChargingModel2?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisChargingModel3?.icon} text={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisChargingModel3?.name} />
                    </div>
                  </div>
                </div>
              </div>
               {/* Contact points & Vendors block */}
               <div className="item-market">
                <div className="item-inner">
                  <div className='flex items-start justify-center'>
                    <h2 className='pr-5'>Contact points & Vendors</h2>
                  </div>
                  <div className='item-inner-logos'>
                    <div className="flex">
                      <KeySpanLogo logo={companyProfileDetails?.proptechPlaceCompanyDetails?.proptechContactPointVendor1?.icon} text={companyProfileDetails?.proptechPlaceCompanyDetails?.proptechContactPointVendor1?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechPlaceCompanyDetails?.proptechContactPointVendor2?.icon} text={companyProfileDetails?.proptechPlaceCompanyDetails?.proptechContactPointVendor2?.name} />
                      <KeySpanLogo logo={companyProfileDetails?.proptechPlaceCompanyDetails?.proptechContactPointVendor3?.icon} text={companyProfileDetails?.proptechPlaceCompanyDetails?.proptechContactPointVendor3?.name} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* Where the market is going block */}
          <div className="market-growth-insights">
            <div className='flex items-start'>
              <h2 className='pr-5'>Where the market is going</h2>
            </div>
            <p>{companyProfileDetails?.proptechPlaceCompanyDetails?.futureSynopsis}</p>
            <div className="growth-info">
              <div className="growth-item">
                <h3>{companyProfileDetails?.proptechPlaceCompanyDetails?.future1Heading}</h3>
                <p>{companyProfileDetails?.proptechPlaceCompanyDetails?.future1}</p>
              </div>
              <div className="growth-item">
                <h3>{companyProfileDetails?.proptechPlaceCompanyDetails?.future2Heading}</h3>
                <p>{companyProfileDetails?.proptechPlaceCompanyDetails?.future2}</p>
              </div>
              <div className="growth-item">
                <h3>{companyProfileDetails?.proptechPlaceCompanyDetails?.future3Heading}</h3>
                <p>{companyProfileDetails?.proptechPlaceCompanyDetails?.future3}</p>
              </div>
            </div>
          </div>
            {/* About the company block */}
            <h2 className="left-bordered-text blue-border print-setting-3">About the company</h2>
            <div className="market-growth-1">
              <div className='flex items-start'>
                <h2 ><span className="number-txt mr-2">1.</span>Commercial problem</h2>
              </div>
              <p className='ml-6'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.problemSynopsis}</p>
              <div className="growth-info">
                <TextWithHeading textClassName="pb-0" className="growth-item" heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.problem1Heading} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.problem1Text} />
                <TextWithHeading textClassName="pb-0" className="growth-item" heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.problem2Heading} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.problem2Text} />
                <TextWithHeading textClassName="pb-0" className="growth-item" heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.problem3Heading} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.problem3Text} />
              </div>
            </div>
            <div className="market-growth-1">
              <div className='flex items-start'>
                <h2 ><span className="number-txt mr-2">2.</span>Solution</h2>
              </div>
              <p className='ml-6'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.solutionSynopsis}</p>
              <div className="growth-info">
                <TextWithHeading textClassName="pb-0" className="growth-item" heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.solution1Heading} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.solution1Text} />
                <TextWithHeading textClassName="pb-0" className="growth-item" heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.solution2Heading} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.solution2Text} />
                <TextWithHeading textClassName="pb-0" className="growth-item" heading={companyProfileDetails?.proptechAnalysisCompanyProfile?.solution3Heading} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.solution3Text} />
              </div>
            </div>
            <div className="key-metrics-1 market-growth-1 has-numbers bg-[#F7F7F7]">
              <div className='flex items-start'>
                <h2 ><span className="number-txt mr-2">3.</span>Business model + go to market</h2>
              </div>
              <div className="modal-design-1 ml-6">
                <div className="col-6">
                  <span>Business model</span>
                  <div className="growth-info">
                    <div className="growth-item business-modal">
                      <h3>{companyProfileDetails?.proptechAnalysisCompanyProfile?.bizModel1Heading}</h3>
                      <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.bizModel1Text}</p>
                    </div>
                    <div className="growth-item business-modal">
                      <h3>{companyProfileDetails?.proptechAnalysisCompanyProfile?.bizModel2Heading}</h3>
                      <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.bizModel2Text}</p>
                    </div>
                    <div className="growth-item business-modal">
                      <h3>{companyProfileDetails?.proptechAnalysisCompanyProfile?.bizModel3Heading}</h3>
                      <p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.bizModel3Text}</p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <span>GO TO MARKET</span>
                  <div className="growth-info">
                    <div className="growth-item business-modal">
                      <h3>{companyProfileDetails?.proptechAnalysisCompanyProfile?.goToMarket1Heading}</h3>
                      <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.goToMarket1Text}</p>
                    </div>
                    <div className="growth-item business-modal">
                      <h3>{companyProfileDetails?.proptechAnalysisCompanyProfile?.goToMarket2Heading}</h3>
                      <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.goToMarket2Text}</p>
                    </div>
                    <div className="growth-item business-modal">
                      <h3>{companyProfileDetails?.proptechAnalysisCompanyProfile?.goToMarket3Heading}</h3>
                      <p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.goToMarket3Text}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="market-growth-1">
              <div className='flex items-start'>
                <h2 ><span className="number-txt mr-2">4.</span>Customers + validation</h2>
              </div>
              <div className="modal-design-1 pl-6">
                <div className="col-6">
                  <span>KEY CUSTOMER TYPE</span>
                  <div className="detail-sectors">
                    <ul className="menu-item">
                      <KeyFeatureLabel icon={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisTechBuyer1?.icon} label={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisTechBuyer1?.name} />
                      <KeyFeatureLabel icon={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisTechBuyer2?.icon} label={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisTechBuyer2?.name} />
                      <KeyFeatureLabel icon={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisTechBuyer3?.icon} label={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisTechBuyer3?.name} />
                    </ul>
                  </div>
                  {/* </div> */}
                  <div className="col-666">
                    <span>KEY CUSTOMERS</span>
                    <div className="supporting-award w-full">
                      {companyProfileDetails?.keyCustomer1 || companyProfileDetails?.keyCustomer2 || companyProfileDetails?.keyCustomer3 ?
                        <div className="  key-customers py-1">
                          <ul className="menu-item key-customer-logo">
                            <Tooltip title={companyProfileDetails?.keyCustomer1} arrow PopperProps={{ style: { zIndex: 1 } }}>
                              {companyProfileDetails?.keyCustomer1Logo ?
                                <li className="keyLogo"><img src={companyProfileDetails?.keyCustomer1Logo} alt="Icon" className='object-contain'/></li>
                                : <li className="keyLogo"><HideImageOutlinedIcon /></li>
                              }
                            </Tooltip>
                            <Tooltip title={companyProfileDetails?.keyCustomer2} arrow PopperProps={{ style: { zIndex: 1 } }}>
                              {companyProfileDetails?.keyCustomer2Logo ?
                                <li className="keyLogo"><img src={companyProfileDetails?.keyCustomer2Logo} alt="Icon" className='object-contain'/></li>
                                : <li className="keyLogo"><HideImageOutlinedIcon /></li>
                              }
                            </Tooltip>
                            <Tooltip title={companyProfileDetails?.keyCustomer3} arrow PopperProps={{ style: { zIndex: 1 } }}>
                              {companyProfileDetails?.keyCustomer3Logo ?
                                <li className="keyLogo"><img src={companyProfileDetails?.keyCustomer3Logo} alt="Icon" className='object-contain' /></li>
                                : <li className="keyLogo"><HideImageOutlinedIcon /></li>
                              }
                            </Tooltip>
                          </ul>
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="metrics-col">
                    <span>METRICS</span>
                    <div className="metrics-wrapper has-three-col">
                      <div className="metrics-item">
                        <span className='font-normal'>CY{currentYear - 1} revenue</span>
                        <p>{companyProfileDetails?.revenueRange?.name}</p>
                      </div>
                      <div className="metrics-item">
                        <span className='font-normal'>Gross margin</span>
                        <p>{companyProfileDetails?.proptechAnalysisNumber?.proptechAnalysisCurrentGrossMargin?.name}</p>
                      </div>
                      <div className="metrics-item">
                        <span className='font-normal'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.keyBizModelTraction2Stat?.name}</span>
                        <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.currentTraction2}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <span>BREAkDOWN</span>
                  {/* <div className="growth-info"> */}
                  <div className="growth-info none-mb-sm has-para">
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.customerBreakdown1Text && <div className="growth-item"><p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.customerBreakdown1Text}</p></div>}
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.customerBreakdown2Text && <div className="growth-item"><p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.customerBreakdown2Text}</p></div>}
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.customerBreakdown3Text && <div className="growth-item"><p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.customerBreakdown3Text}</p></div>}
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.noBreakdown === true &&
                      <div><p>No breadown details provided.</p></div>
                    }
                    {/* </div> */}
                  </div>
                  <span>KEY POINTS</span>
                  <div className="growth-info has-para">
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.customerKeyPoint1Text && <div className="growth-item"><p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.customerKeyPoint1Text}</p></div>}
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.customerKeyPoint2Text && <div className="growth-item"><p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.customerKeyPoint2Text}</p></div>}
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.customerKeyPoint3Text && <div className="growth-item"><p className='pb-0'>{companyProfileDetails?.proptechAnalysisCompanyProfile?.customerKeyPoint3Text}</p></div>}
                    {companyProfileDetails?.proptechAnalysisCompanyProfile?.noKeyPoint === true &&
                      <div><p>No key point provided.</p></div>
                    }
                  </div>
                </div>
              </div>
              <div className="modal-design-1">
                <div className="col-6">
                </div>
                <div className="col-6">
                </div>
              </div>
              <div className="modal-design-1">
                <div className="col-6">
                </div>
              </div>
            </div>
            <div className="market-growth-1 border-none print-setting-1 key-metrics-1 bg-[#F7F7F7]">
              <div className='flex items-start'>
                <h2 ><span className="number-txt mr-2">5.</span>Past projects</h2>
              </div>
              <div className="metrics-wrapper ml-1">
                <div className="metrics-item">
                  <span>Amount</span>
                  <p>{companyProfileDetails?.proptechPlaceCompanyDetails?.pastProjectRange?.name || "-"}</p>
                </div>
                <div className="metrics-item">
                  <span>Geography</span>
                  <p>{companyProfileDetails?.proptechPlaceCompanyDetails?.placeGeography?.name || "-"}</p>
                </div>
                <div className="metrics-item">
                  <span>Scale</span>
                  <p>{companyProfileDetails?.proptechPlaceCompanyDetails?.projectScale?.name || "-"}</p>
                </div>
                <div className="metrics-item">
                  <span>{companyProfileDetails?.proptechAnalysisCompanyProfile?.keyBizModelTraction2Stat?.name || "-"}</span>
                  <p>{companyProfileDetails?.proptechAnalysisCompanyProfile?.currentTraction2 || "-"}</p>
                </div>
              </div>
            </div>
            <div className="market-growth-1">
              <div className='flex items-start'>
                <h2 ><span className="number-txt mr-2">6.</span>Value proposition</h2>
              </div>
              <div className='flex items-center ml-6 my-4 gap-8 flex-wrap md:flex-nowrap'>
                <div className='flex items-center gap-2 md:w-1/4'>
                  <img src={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI1?.icon} alt='' className='value-prop'/>
                  <p className='pb-0 text-xs'>{companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI1?.name}</p>
                </div>
                <div className='flex items-center gap-2 md:w-1/4'>
                  <img src={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI2?.icon} alt='' className='value-prop'/>
                  <p className='pb-0 text-xs'>{companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI2?.name}</p>
                </div>
                <div className='flex items-center gap-2 md:w-1/4'>
                  <img src={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI3?.icon} alt='' className='value-prop'/>
                  <p className='pb-0 text-xs'>{companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI3?.name}</p>
                </div><div className='flex items-center gap-2 md:w-1/4'>
                  <img src={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI4?.icon} alt='' className='value-prop'/>
                  <p className='pb-0 text-xs'>{companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI4?.name}</p>
                </div>
              </div>
              <div className="modal-design-1 ml-4">
                <div className="col-6">
                  <div className="growth-info">
                    <TextWithHeading className="growth-item business-modal" heading={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI1?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.valueKPI1Text} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="growth-info">
                    <TextWithHeading className="growth-item business-modal" heading={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI2?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.valueKPI2Text} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="growth-info">
                    <TextWithHeading className="growth-item business-modal pb-0" heading={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI3?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.valueKPI3Text} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="growth-info">
                    <TextWithHeading className="growth-item business-modal pb-0" heading={companyProfileDetails?.proptechAnalysisMarket?.proptechAnalysisValueKPI4?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.valueKPI4Text} />
                  </div>
                </div>
              </div>
            </div>
            {/* What they’re raising block */}
            <div className="blue-theme">
            <h2 className="left-bordered-text blue-border print-setting-2">Integration & Asset fit</h2>
              <div className="market-growth-1 information-growth">
                <div className='flex items-start'>
                  <h2 ><span className="number-txt mr-2">A.</span>Integration process</h2>
                </div>
                <div className="growth-info ml-6">
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisDeployUse?.integrationKey1?.icon} heading={companyProfileDetails?.proptechAnalysisDeployUse?.integrationKey1?.name} text={companyProfileDetails?.proptechAnalysisDeployUse?.integrationText1} />
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisDeployUse?.integrationKey2?.icon} heading={companyProfileDetails?.proptechAnalysisDeployUse?.integrationKey2?.name} text={companyProfileDetails?.proptechAnalysisDeployUse?.integrationText2} />
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisDeployUse?.integrationKey3?.icon} heading={companyProfileDetails?.proptechAnalysisDeployUse?.integrationKey3?.name} text={companyProfileDetails?.proptechAnalysisDeployUse?.integrationText3} />
                </div>
              </div>
              <div className="market-growth-1 information-growth print-setting-1">
                <div className='flex items-start'>
                  <h2 ><span className="number-txt mr-2">B.</span>Risk incurred</h2>
                </div>
                <div className="growth-info ml-6">
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisDeployUse?.proptechRisk1?.icon} heading={companyProfileDetails?.proptechAnalysisDeployUse?.proptechRisk1?.name}  text={companyProfileDetails?.proptechAnalysisDeployUse?.riskText2}/>
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisDeployUse?.proptechRisk2?.icon} heading={companyProfileDetails?.proptechAnalysisDeployUse?.proptechRisk2?.name} text={companyProfileDetails?.proptechAnalysisDeployUse?.riskText2}/>
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisDeployUse?.proptechRisk3?.icon} heading={companyProfileDetails?.proptechAnalysisDeployUse?.proptechRisk3?.name}  text={companyProfileDetails?.proptechAnalysisDeployUse?.riskText3}/>
                </div>
              </div>
              <div className="market-growth-1 information-growth">
                <div className='flex items-start mb-4'>
                  <h2 ><span className="number-txt mr-2">C.</span>Tech review</h2>
                </div>
                <div className='md:mb-2 max-w-[920px] ml-6'>
                  {
                    companyProfileDetails?.proptechRadarValues?.length ?    
                    <StackedBarChart 
                      datasets={companyProfileDetails?.proptechRadarValues?.map(item => Number(item.value))}
                      labels={companyProfileDetails?.proptechRadarValues?.map(item => item.name)}
                      chartData={companyProfileDetails?.proptechRadarValues}
                    /> : "-"
                  }
                </div>
              </div>
              <div className="market-growth-1 information-growth">
                <div className='flex items-start'>
                  <h2 ><span className="number-txt mr-2">D.</span>Team</h2>
                </div>
                <div className="growth-info ml-6">
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisTeam?.proptechAnalysisTeamSkill1?.icon} heading={companyProfileDetails?.proptechAnalysisTeam?.proptechAnalysisTeamSkill1?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.skill1Text} />
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisTeam?.proptechAnalysisTeamSkill2?.icon} heading={companyProfileDetails?.proptechAnalysisTeam?.proptechAnalysisTeamSkill2?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.skill2Text} />
                  <VerticalLogoWithHeading logo={companyProfileDetails?.proptechAnalysisTeam?.proptechAnalysisTeamSkill3?.icon} heading={companyProfileDetails?.proptechAnalysisTeam?.proptechAnalysisTeamSkill3?.name} text={companyProfileDetails?.proptechAnalysisCompanyProfile?.skill3Text} />
                </div>
              </div>
            </div>
          </div>
          : null
      }
    </>
  )
}
