export const Actions = {
  Loader: {
    LoadingStart: 'LOADING_START',
    LoadingStop: 'LOADING_STOP'
  },
  TBAuth: {
    TBLoginSuccess: 'TB_AUTH_LOGIN_SUCCESS',
    TBLoginFailed: 'TB_AUTH_LOGIN_FAILED',
    TBReset: 'TB_AUTH_RESET',
  },
  TBUser: {
    TBUserSet: 'TB_USER_SET',
    TBUserRemove: 'TB_USER_REMOVE',
    SetNotificationCheckUnread: 'TB_NOTIFICATION_CHECK_UNREAD',
    SetNotifications: 'TB_SET_NOTIFICATIONS',
    setNotificationLoading: 'SET_NOTIFICATION_LOADING',
    SetActivePlan: 'SET_TB_ACTIVE_PLAN',
    SetCountDetails: 'SET_TB_COUNT_DETAILS',
    SetChangePlanModal: 'SET_TB_CHANGE_PLAN_MODAL',
    TBUserReset: 'TB_USER_RESET',
    SetNavTabs: 'SET_TB_NAV_TABS',
    SetActiveMainTab: 'SET_ACTIVE_MAIN_TAB'
  },
  TBProptechs: {
    TBProptechsSet: 'TB_PROPTECHS_LIST_SET',
    TBWatchlistSet: 'TB_WATCHLIST_SET',
    TBWatchlistPageSet: 'TB_WATCHLIST_PAGE_SET',
    TBWatchlistPageSizeSet: 'TB_WATCHLIST_PAGESIZE_SET',
    TBResetList: 'TB_RESET_LIST',
    TBProptechsReset: 'TB_PROPTECHS_RESET',
    TBShowModal: 'TB_SHOW_MODAL',
    TBSetFilter: 'TB_SET_FILTER_OPTION',
    TBSetOrder: 'TB_SET_ORDER',
    TBSetOrderBy: 'TB_SET_ORDER_BY',
    TBSetPage: 'TB_SET_PAGE',
    TBSetPageSize: 'TB_SET_PAGESIZE',
    TBSetLoading: 'TB_SET_LOADING',
    TBSetMasterData: 'TB_SET_MASTERDATA',
    TBSetActiveUsers: 'TB_SET_ACTIVEUSERS',
    TBSetActiveTab: 'TB_SET_ACTIVETAB',
    TBProjectTabData: 'TB_PROJECT_TAB_DATA'
  },
  TBForYou: {
    TBForyouSet: 'TB_FORYOU_LIST_SET',
    TBSetPage: 'TB_SET_PAGE_FORYOU',
    TBSetPageSize: 'TB_SET_PAGESIZE_FORYOU',
  },
  TBProptechDetails: {
    setTBProptechDetails: 'SET_TB_PROPTECH_DETAILS',
    setTBOverviewDetails: 'SET_TB_OVERVIEW_DETAILS',
    setTBMarketDetails: 'SET_TB_MARKET_DETAILS',
    setTBNumbersDetails: 'SET_TB_NUMBERS_DETAILS',
    setTBDeployAndUseDetails: 'SET_TB_DEPLOY_AND_USE_DETAILS',
    setTBDesignReviewDetails: 'SET_TB_DESIGNREVIEW_DETAILS',
    setTBMarketInsightsDetails: 'SET_TB_MARKETINSIGHTS_DETAILS',
    setTBCompanyProfileDetails: 'SET_TB_COMPANYPROFILE_DETAILS',
    setTBApiTimeout: 'SET_TB_APITIMEOUT',
    TBProptechDetailsReset: 'TB_PROPTECH_DETAILS_RESET',
    setTBKeyAttributesDetails: 'SET_TB_KEY_ATTRIBUTES_DETAILS',
    setTBPastProjectsDetails: 'SET_TB_PAST_PROJECT_DETAILS'
  },
  Snackbar: {
    SnackbarShow: 'SNACKBAR_SHOW',
    SnackbarHide: 'SNACKBAR_HIDE',
    SnackbarMessage: 'SNACKBAR_MESSAGE',
    SnackbarMessageType: 'SNACKBAR_MESSAGE_TYPE',
    SnackbarReset: 'SNACKBAR_RESET',
  },
  PublicProptechUser: {
    SetId: 'SET_ID',
    SetIsVerified: 'SET_IS_VERIFIED',
    SetUserReset: 'USER_RESET'
  },
};
