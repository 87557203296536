import { TB_PROPTECH_LISTING } from "../../../../constants/APIs";
import { Actions } from "../../../../store/actions";
import { Store } from "../../../../store/configureStore";
import { PUBLIC_API } from "../../../../utils/utility/API-call";
import { handleError } from "../../../../utils/utility/snackbar";
import { apiTimeoutTime } from "../../../constants";
import { deployAndUseDTO } from "../../dtos/deployAndUseTab";
import { marketInsightsTabTBDTO } from "../../dtos/marketInsightsTB";
import { marketTabTBDTO } from "../../dtos/marketTB";
import { numbersTabTBDTO } from "../../dtos/numbersTB";
import { overviewTabTBDTO } from "../../dtos/overviewTB";
import { proptechDetailsDTO } from "../../dtos/proptechDetailsDTO";

export const getOverviewAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return PUBLIC_API()
    .get(`${TB_PROPTECH_LISTING}/overview/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBOverviewDetails,
        payload: overviewTabTBDTO(res?.data?.data?.proptechCompanyOverviewDetails),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBProptechDetails,
        payload: proptechDetailsDTO(res?.data?.data?.proptechCompanyDetails),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBApiTimeout,
        payload: { overview: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.TBProptechDetails.setTBApiTimeout,
          payload: { overview: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      handleError(err);
      setIsLoading(false);
      return err?.status;
    });
};

export const getOfferingAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return PUBLIC_API()
    .get(`${TB_PROPTECH_LISTING}/market/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBMarketDetails,
        payload: marketTabTBDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBApiTimeout,
        payload: { market: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.TBProptechDetails.setTBApiTimeout,
          payload: { market: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getTrackRecordAPI = (proptechCompanyDetailsId, setIsLoading) => {
  getPastProjectsData(proptechCompanyDetailsId)
  return PUBLIC_API()
    .get(`${TB_PROPTECH_LISTING}/numbers/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBNumbersDetails,
        payload: numbersTabTBDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBApiTimeout,
        payload: { numbers: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.TBProptechDetails.setTBApiTimeout,
          payload: { numbers: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getDeployAndUseAPI = (proptechCompanyDetailsId, setIsLoading) => {
  return PUBLIC_API()
    .get(`${TB_PROPTECH_LISTING}/deployUse/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBDeployAndUseDetails,
        payload: deployAndUseDTO(res?.data?.data),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBApiTimeout,
        payload: { deployAndUse: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.TBProptechDetails.setTBApiTimeout,
          payload: { deployAndUse: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getMarketInsightsAPI = (
  proptechCompanyDetailsId,
  setIsLoading
) => {
  return PUBLIC_API()
    .get(`${TB_PROPTECH_LISTING}/marketInsights/${proptechCompanyDetailsId}`)
    .then((res) => {
      setIsLoading(false);
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBMarketInsightsDetails,
        payload: marketInsightsTabTBDTO(res?.data?.data?.marketInsight),
      });
      Store.dispatch({
        type: Actions.TBProptechDetails.setTBApiTimeout,
        payload: { marketInsights: false },
      });
      setTimeout(() => {
        Store.dispatch({
          type: Actions.TBProptechDetails.setTBApiTimeout,
          payload: { marketInsights: true },
        });
      }, apiTimeoutTime);
      if (res?.data?.data) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      setIsLoading(false);
      handleError(err);
      return err?.status;
    });
};

export const getPastProjectsData = (proptechCompanyDetailsId) => {
  return PUBLIC_API().get(`${TB_PROPTECH_LISTING}/pastProjects/${proptechCompanyDetailsId}`).then((res) => {
    Store.dispatch({ type: Actions.TBProptechDetails.setTBPastProjectsDetails, payload: (res?.data?.data) });
    if (res?.data?.data) {
      return res?.data?.data
    }
  }).catch((err) => {
    handleError(err)
    return err?.status;
  })
}