import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../components/Loading';
import NotFound from '../pages/NotFound'

// Place
import PlacePublicRoute from '../place/routes/PublicRoute';
import PlacePrivateRoute from '../place/routes/PrivateRoute';
import PlaceLanding from '../place/pages/Landing';
import PlaceInterest from '../place/pages/Interest';
import PlaceLogin from '../place/pages/Login';
import PlaceSignup from '../place/pages/Signup';
import PlaceResetPassword from '../place/pages/ResetPassword';
import PlaceForgotPassword from '../place/pages/ForgotPassword';
import PlaceCheckEmail from '../place/pages/CheckEmail';
import PlaceEmailVerification from '../place/pages/EmailVerification';
import PlaceDashboard from '../place/pages/Dashboard';
import PlaceProptechDetail from '../place/pages/ProptechDetail';
import PlacePublicAuth from '../place/pages/PlacePublicProptechDetailsPage/Auth';
import PublicPlaceProptechDetails from '../place/pages/PlacePublicProptechDetailsPage/ProptechDetails';


function Router() {
  const { isLoading } = useSelector(state => state.loader);

  return (
    <BrowserRouter>
      {isLoading ? <Loader /> : null}
      <Routes >
         {/* Place Routes */}
        <Route path="/" exact element={<PlacePublicRoute><PlaceLanding /></PlacePublicRoute>} />
        <Route path="/login" exact element={<PlacePublicRoute><PlaceLogin /></PlacePublicRoute>} />
        <Route path="/signup" exact element={<PlacePublicRoute><PlaceSignup /></PlacePublicRoute>} />
        <Route path="/thanksforinterest" exact element={<PlacePublicRoute><PlaceInterest /></PlacePublicRoute>} />
        <Route path="/forgotpassword" exact element={<PlacePublicRoute><PlaceForgotPassword /></PlacePublicRoute>} />
        <Route path="/resetpassword" exact element={<PlacePublicRoute><PlaceResetPassword /></PlacePublicRoute>} />
        <Route path="/checkemail" exact element={<PlacePublicRoute><PlaceCheckEmail /></PlacePublicRoute>} />
        <Route path="/emailverification" exact element={<PlacePublicRoute><PlaceEmailVerification /></PlacePublicRoute>} />
        <Route path="/dashboard" exact element={<PlacePrivateRoute><PlaceDashboard /></PlacePrivateRoute>} />
        <Route path="/proptech/:proptechId" exact element={<PlacePrivateRoute><PlaceProptechDetail /></PlacePrivateRoute>} />
        <Route path="/shared/:id" exact element={<><PlacePublicAuth /></>} />
        <Route path="/shared/:id/proptech" exact element={<><PublicPlaceProptechDetails /></>} />
        <Route path='*' element={<NotFound />} />
      </Routes >
    </BrowserRouter >
  );
}

export default Router;
