import { TB_PROPTECH_LISTING, TB_FORYOU, TB_INVITE_API, TB_WATCHLIST, TB_PROPTECH_LISTING_HOME, TB_PROJECTS } from "../../../../constants/APIs"
import { Actions } from "../../../../store/actions"
import { Store } from "../../../../store/configureStore"
import { TB_API } from "../../../../utils/utility/API-call"
import { handleError, handleSuccess } from "../../../../utils/utility/snackbar"
import { dispatch } from "../../../../utils/utility/store"
import { forYouDTO } from "../../dtos/forYouDTO"
import { getProptechDetailsAPI } from "../ProptechDetails"

export const getProptechs = async (filterFields, orderBy, order, page = 1, size = 10, search) => {
  const params = {
    filter: filterFields,
    page: page,
    size: size,
    orderBy: orderBy || undefined,
    order: order || undefined,
    ...(!!search ? { search } : {}),
  }
  return TB_API().get(`${TB_PROPTECH_LISTING_HOME}`, { params }).then((res) => {
    if (res?.data) {
      if (res?.data?.data?.rows) {
        dispatch(Actions.TBProptechs.TBProptechsSet, res?.data?.data)
        return res?.data?.data
      }
    }
  }).catch((err) => {
    handleError(err)
    return err?.status;
  })
}

export const getWatchlist = async (filterFields, orderBy, order, page = 1, size = 10) => {
  const params = {
    filter: filterFields,
    page: page,
    size: size,
    orderBy: orderBy || undefined,
    order: order || undefined,
  }
  Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: true });
  return TB_API().get(TB_WATCHLIST, { params }).then((res) => {
    Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: false });
    if (res?.data) {
      if (res?.data?.data?.rows) {
        dispatch(Actions.TBProptechs.TBWatchlistSet, res?.data?.data)
        return res?.data?.data
      }
    }
  }).catch((err) => {
    Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: false });
    // handleError(err)
    return err?.status;
  })
}

export const setWatchlistStatus = async (id, tab, isFavorite, tbProptechs, tbForYou, activeMainTab) => {
  Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: true });
  return TB_API().put(`${TB_PROPTECH_LISTING}/${id}/favorites`, { "isFavorite": !isFavorite, "navigation" : activeMainTab === 1 ? "Home" : activeMainTab === 2 ? "For you" : null }).then((res) => {
    Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: false });
    handleSuccess(res)
    if (res?.data?.code === 200) {
      if (tab === 0) {
        getProptechs(tbProptechs?.filter, tbProptechs?.orderBy, tbProptechs?.order, tbProptechs?.page + 1, tbProptechs?.size);
      } else if (tab === 1) {
        getForyou(tbProptechs?.filter, tbProptechs?.orderBy, tbProptechs?.order, tbForYou?.forYouPage + 1, tbForYou?.forYouPageSize)
      } else if (tab === 2) {
        getWatchlist()
      } else if (tab === 'proptechDetails') {
        getProptechDetailsAPI(id)
        getProptechs(tbProptechs?.filter, tbProptechs?.orderBy, tbProptechs?.order, tbProptechs?.page + 1, tbProptechs?.size);
        getWatchlist()
      }
      return res?.data?.data
    }
  }).catch((err) => {
    Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: false });
    handleError(err)
    return err?.status;
  })
}

export const updateNote = async (id, note, tab, tbProptechs, tbForYou) => {
  Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: true });
  return TB_API().put(`${TB_PROPTECH_LISTING}/${id}/notes`, { "note": note }).then((res) => {
    handleSuccess(res)
    Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: false });
    if (res?.data?.data) {
      if (tab === 0) {
        getProptechs(tbProptechs?.filter, tbProptechs?.orderBy, tbProptechs?.order, tbProptechs?.page + 1, tbProptechs?.size);
      } else if (tab === 1) {
        getForyou(tbProptechs?.filter, tbProptechs?.orderBy, tbProptechs?.order, tbForYou?.forYouPage + 1, tbForYou?.forYouPageSize)
      } else if (tab === 2) {
        getWatchlist()
      } else if (tab === 'proptechDetails') {
        getProptechDetailsAPI(id)
        getProptechs(tbProptechs?.filter, tbProptechs?.orderBy, tbProptechs?.order, tbProptechs?.page + 1, tbProptechs?.size);
        getWatchlist()
      }
      return res?.data?.data?.note
    }
  }).catch((err) => {
    Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: false });
    handleError(err)
    return err?.status;
  })
}

export const getForyou = async (filterFields, orderBy, order, page = 1, size = 10) => {
  const params = {
    filter: filterFields,
    page: page,
    size: size,
    orderBy: orderBy || undefined,
    order: order || undefined,
  }
  dispatch(Actions.TBProptechs.TBSetLoading, true);
  return TB_API().get(TB_FORYOU, { params }).then((res) => {
    dispatch(Actions.TBProptechs.TBSetLoading, false);
    if (res?.data && res?.data?.data?.rows) {
      dispatch(Actions.TBForYou.TBForyouSet, forYouDTO(res?.data?.data));
      return res?.data?.data
    }
    handleError(res?.data)
  }).catch((err) => {
    dispatch(Actions.TBProptechs.TBSetLoading, false);
    handleError(err)
    return err?.status;
  })
}

export const inviteMember = async (email, message) => {
  dispatch(Actions.TBProptechs.TBSetLoading, true);
  return TB_API().post(`${TB_INVITE_API}`, { "email": email, "message": message.trim() }).then((res) => {
    handleSuccess(res)
    dispatch(Actions.TBProptechs.TBSetLoading, false);
  }).catch((err) => {
    Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: false });
    handleError(err)
    return err?.status;
  })
}

export const shareProptech = (proptechId, emailIds, message) => {
  let params = {
    tbUserIds: emailIds,
    message: message.trim()
  }
  Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: true });
  return TB_API().put(`${TB_PROPTECH_LISTING}/${proptechId}/share`, params).then((res) => {
    Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: false });
    handleSuccess(res)
    return res?.data?.data
  }).catch((err) => {
    Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: false });
    handleError(err)
    return err?.status;
  })
}

export const getProjectTabData = async (email, message) => {
  dispatch(Actions.TBProptechs.TBSetLoading, true);
  return TB_API().get(`${TB_PROJECTS}/detail`).then((res) => {
    handleSuccess(res)
    dispatch(Actions.TBProptechs.TBProjectTabData, { companyOtherDetails: res.data?.data?.companyOtherDetails, ...res.data?.data?.propjectOverviewDetails
    })
    dispatch(Actions.TBProptechs.TBSetLoading, false);
  }).catch((err) => {
    Store.dispatch({ type: Actions.TBProptechs.TBSetLoading, payload: false });
    // handleError(err)
    return err?.status;
  })
}
