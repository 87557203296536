import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Modal } from '@mui/material';
import { useSelector } from 'react-redux';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import PTButton from '../../../../components/GenericComponents/PTButton';
import mapIcon from '../../../../assets/Icons/ic-map-place.svg';
import communicationIcon from '../../../../assets/Icons/ic-communication.svg';
import communicationIcon2 from '../../../../assets/Icons/ic-communication-filled.svg';
import shareIcon from '../../../../assets/Icons/ic-share.svg';
import starIcon from '../../../../assets/Icons/ic-star.svg';
import starIconActive from '../../../../assets/Icons/StarIconActive.svg';
import closeIcon from '../../../../assets/Icons/ic-cross.svg';
import { setWatchlistStatus } from '../../../utils/middleware/Dashboard';
import Loader from '../../../../components/Loading';
import PTInput from '../../../../components/GenericComponents/PTInput';
import { updateNote } from '../../../utils/middleware/Dashboard';
import ShareModal from '../../ShareModal';
import { getActiveUsers } from '../../../utils/middleware/Dropdowns';
import { TooltipText } from '../../../../constants/General';

export default function TopBlock() {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  // Store
  const { proptechDetails } = useSelector(state => state.tbProptechDetails);
  const { isLoading } = useSelector(state => state.tbProptechs)
  const tbProptechs = useSelector(state => state.tbProptechs);
  const {activeMainTab} = useSelector(state => state.tbUser)

  // State
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [noteData, setNoteData] = useState("");
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareMessage, setShareMessage] = useState("");
  const [activeUsers, setActiveUsers] = useState([])
  const handleNoteCloseModal = () => setOpenNoteModal(false);

  const handleShareCloseModal = () => {
    setOpenShareModal(false)
    setShareMessage('')
  };

  // Set note data
  useEffect(() => {
    if (proptechDetails && proptechDetails?.tbProptechNote.length) {
      setNoteData(proptechDetails?.tbProptechNote[0]?.note)
    }
  }, [proptechDetails])

  const handleNoteSumit = () => {
    updateNote(proptechDetails?.id, noteData, 'proptechDetails', tbProptechs).then(() => {
      setOpenNoteModal(false)
    })
  }

  const handleNoteModal = () => {
    setOpenNoteModal(true)
  }

  const handleShareModal = async () => {
    setOpenShareModal(true)
    if (tbProptechs.tbActiveUsers.length) {
      setActiveUsers(tbProptechs.tbActiveUsers)
    } else {
      getActiveUsers().then((res) => {
        setActiveUsers(res)
      })
    }
  }


  return (
    <div className="top-menu-place">
      {isLoading ? <Loader /> : null}
      <div className="relative">
        <div className="icon-wrapper">
          {/* Note Modal Button */}
          <Tooltip title={TooltipText.comment} arrow >
            <IconButton onClick={handleNoteModal}>
                <img src={proptechDetails?.tbProptechNote[0]?.note?.trim().length > 0 ? communicationIcon2 : communicationIcon} alt="Icon" />
              </IconButton>
          </Tooltip>
          {/* Share Modal Button */}
          <Tooltip title={TooltipText.share} arrow >
            <IconButton onClick={handleShareModal}>
              <img src={shareIcon} alt="Icon" />
            </IconButton>
          </Tooltip>
          {/* Set Watchlist Button */}
          <Tooltip title={TooltipText.watchlist} arrow >
            <IconButton
              onClick={() => setWatchlistStatus(proptechDetails?.id, 'proptechDetails', !!proptechDetails?.tbProptechFavorite.length, tbProptechs,  null, activeMainTab)}
            >
              <img src={proptechDetails?.tbProptechFavorite.length ? starIconActive : starIcon} alt="Icon" />
            </IconButton>
          </Tooltip>
        </div>
        <div className="left_col-place">
          {/* Proptech details wrapper */}
          <div className="nav-top flex">
            <i className="image-wrapper">
              {proptechDetails?.companyLogo ?
                <img src={proptechDetails?.companyLogo} alt="Logo" className='object-contain bg-white' />
                : <HideImageOutlinedIcon />
              }
            </i>
            <div className="card-info">
              <h2>{proptechDetails?.proptechCompany?.name}</h2>
              <ul className="listing-item">
              </ul>
              <ul className="listing-item-place badge-item">
                <Tooltip title={TooltipText.companyRegion} arrow >
                  <li>
                    <img src={mapIcon} alt="Icon" />
                    {proptechDetails?.region?.name}
                  </li>
                </Tooltip>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openNoteModal}
        onClose={handleNoteCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="meeting-cancel-modal modal"
      >
        <>
          <Box sx={style} >
            <IconButton className="close-btn" onClick={() => handleNoteCloseModal()}>
              <img src={closeIcon} alt="Icon" />
            </IconButton>
            <div className="modal-outer relative">
              <div className="modal-inner">
                <h2>Add a note</h2>
              </div>
              <form>
                <div className="form-wrapper">
                  <div className="form-group">
                    <PTInput
                      id="note"
                      name="note"
                      className='form-control textarea-input'
                      multiline="multiline"
                      placeholder="Enter a note for later"
                      label="Enter a note for later"
                      fullWidth
                      value={noteData}
                      handleOnChangeEvent={(e) => setNoteData(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="btn-wrapper h-11">
              <PTButton label='Cancel' className="fill-gray btn h-11"
                onClick={() => setOpenNoteModal(false)}
              />
              <PTButton label='Save' handleOnClickEvent={() => handleNoteSumit()} className="fill-blue h-11 btn" />
            </div>
          </Box>
        </>
      </Modal>
      {/* Share Modal */}
      <ShareModal
        shareMessage={shareMessage}
        openShareModal={openShareModal}
        handleShareCloseModal={() => handleShareCloseModal()}
        activeUsers={activeUsers}
        proptechId={proptechDetails?.id}
      />
    </div>)
}
