import { ClickAwayListener, Tooltip } from '@mui/material'
import React from 'react'
import Info from '../../assets/Icons/info.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function InfoIcon({ handleTooltipClose, info, handleTooltipOpen, label, className, icon }) {
  return (
    <>
      <ClickAwayListener onClickAway={() => info.includes(label) && handleTooltipClose(label)}>
        <Tooltip
          arrow
          // PopperProps={{
          //   disablePortal: true,
          // }}
          // onClose={() => handleTooltipClose(label)}
          // open={info && info.includes(label)}
          disableFocusListener
          disableTouchListener
          title={label}
        >
          {icon ? <InfoOutlinedIcon
            fontSize="small"
            className={`cursor-pointer ${className}`}
            onClick={() => info.length ?
              handleTooltipClose(label) :
              handleTooltipOpen(label)
            } /> : <img
            src={Info}
            alt="Icon"
            className={`w-4 cursor-pointer ${className}`}
            onClick={() => info.length ?
              handleTooltipClose(label) :
              handleTooltipOpen(label)
            }
          />}
        </Tooltip>
      </ClickAwayListener>
    </ >
  )
}
