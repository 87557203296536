import React from 'react';
import { useNavigate } from 'react-router-dom';
import PlaceLogo from '../../assets/Icons/place-logo.svg';
import './style.css';


export default function LeftLogo() {
  const navigate = useNavigate();

  return (
    <>
      <div className={`background-blue background-place md:min-h-screen`}>
        <img src={ PlaceLogo } alt='' className='cursor-pointer z-10' onClick={() => navigate('/', { replace: true })} />
      </div>
    </>
  )
}