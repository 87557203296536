import { Avatar } from "@mui/material";
import React, { useState } from "react";
import { stringAvatar } from "../../../utils/utility/utility";

const CompanyLogo = ({ companyLogo, name }) => {
  const [validLogo, setValidLogo] = useState(true);
  return (
    <div className="image-wrapper cursor-pointer flex items-center overflow-hidden justify-center bg-white">
      {companyLogo && validLogo ? (
        <img
          src={companyLogo}
          alt={""}
          onError={() => setValidLogo(false)}
          className="object-contain"
        />
      ) : (
        <Avatar sx={{ fontStyle: "normal" }} {...stringAvatar(name)}></Avatar>
      )}
    </div>
  );
};

export default CompanyLogo;
