import React from 'react';
import TablePagination from '@mui/material/TablePagination';

export default function Pagination({ length, tab, page, size, handlePageChange, handleRowChange }) {

  return (
    <>
      <TablePagination
        component="div"
        count={length}
        page={page}
        onPageChange={(event, pageNum) => handlePageChange(pageNum)}
        rowsPerPage={size}
        onRowsPerPageChange={(e) => handleRowChange(e.target.value)}
        labelRowsPerPage={`${tab} per page`}
      />
    </>
  );
}
