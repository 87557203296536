import React, { Fragment, useState } from "react";
import "./styles.scss";
import fileIcon from "../../../assets/Icons/ic-file.svg";
import mapIcon from "../../../assets/Icons/ic-map-place.svg";
import userBg from "../../../assets/Icons/ic-user-bg.svg";
import downloadIcon from "../../../assets/Icons/ic-download-place.svg";
import { Box, Tooltip } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { KeySpanLogo } from "../../components/Label";
import PTButton from "../../../components/GenericComponents/PTButton";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { useSelector } from "react-redux";
import {
  extractFilenameFromURL,
  numFormatter,
  numFormatterProject,
} from "../../../utils/utility/utility";
import { investmentSize } from "../../constants/index";
import moment from "moment";
import CustomizedTimeline from "../../components/Timeline";
import NorthIcon from "@mui/icons-material/North";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { projectPageTabs } from "../../constants";
import CompanyLogo from "../../components/CompanyLogo";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  color: "#111D43",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function ProjectTab() {
  const { tbProjectTabData, isLoading } = useSelector(
    (state) => state.tbProptechs
  );

  const [openTabs, setOpenTabs] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    expandedIntegration: false,
    expandedDecisionMaking: false,
    expandProblem: false,
    expandOverview: false,
    expandPtcRemit: false,
    expandCriteria: false,
  });

  const onDownloadSummary = () => {
    const openAllTabs = {};
    Object.entries(openTabs).forEach(([key, val], idx) => {
      openAllTabs[key] = true;
    });
    setOpenTabs(openAllTabs);
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  const content = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="market-overview-place">
            <div className="item-market-2 overview-project">
              <div className="item-inner">
                {tbProjectTabData?.overview !== null ? (
                  <>
                    <p className="summary-project w-full">
                      {String(
                        tbProjectTabData?.overview || "No data found"
                      ).substring(
                        0,
                        openTabs.expandOverview
                          ? tbProjectTabData?.overview.length
                          : 224
                      )}
                    </p>
                    {tbProjectTabData?.overview?.length > 224 && (
                      <h2
                        id="see-more-btn"
                        className="text-left text-sm !pb-0 !h-4 cursor-pointer"
                        onClick={() =>
                          setOpenTabs({
                            ...openTabs,
                            expandOverview: !openTabs.expandOverview,
                          })
                        }
                      >
                        See more{" "}
                        <ExpandMore
                          expand={openTabs.expandOverview}
                          aria-expanded={openTabs.expandOverview}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </h2>
                    )}
                  </>
                ) : (
                  <p className="summary-project w-full">No data found</p>
                )}
              </div>
            </div>
            <div className="item-market">
              <div className="item-inner">
                <div className="flex items-start justify-center">
                  <h2 className="pr-4">Strategic focus</h2>
                </div>
                <div className="item-inner-logos">
                  <div className="flex items-start justify-center">
                    <KeySpanLogo
                      logo={tbProjectTabData?.proptechAnalysisFocus1?.icon}
                      text={tbProjectTabData?.proptechAnalysisFocus1?.name}
                    />
                    <KeySpanLogo
                      logo={tbProjectTabData?.proptechAnalysisFocus2?.icon}
                      text={tbProjectTabData?.proptechAnalysisFocus2?.name}
                    />
                    <KeySpanLogo
                      logo={tbProjectTabData?.proptechAnalysisFocus3?.icon}
                      text={tbProjectTabData?.proptechAnalysisFocus3?.name}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item-market">
              <div className="item-inner">
                <div className="flex items-start justify-center">
                  <h2 className="pr-4">Asset sectors</h2>
                </div>
                <div className="item-inner-logos">
                  <div className="flex items-start gap-2 justify-center">
                    <KeySpanLogo
                      logo={tbProjectTabData?.proptechAnalysisSector1?.icon}
                      text={tbProjectTabData?.proptechAnalysisSector1?.name}
                    />
                    <KeySpanLogo
                      logo={tbProjectTabData?.proptechAnalysisSector2?.icon}
                      text={tbProjectTabData?.proptechAnalysisSector2?.name}
                    />
                    <KeySpanLogo
                      logo={tbProjectTabData?.proptechAnalysisSector3?.icon}
                      text={tbProjectTabData?.proptechAnalysisSector3?.name}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <>
            <div className="market-overview-place">
              <div className="item-market-2 overview-project">
                <div className="item-inner min-h-[180px]">
                  <h2 className="pr-4 text-left">Problem</h2>
                  {tbProjectTabData?.problem !== null ? (
                    <>
                      <p className="summary-project w-full">
                        {String(
                          tbProjectTabData?.problem || "No data found"
                        ).substring(
                          0,
                          openTabs.expandProblem
                            ? tbProjectTabData?.problem.length
                            : 224
                        )}
                      </p>
                      {tbProjectTabData?.problem?.length > 224 && (
                        <h2
                          id="see-more-btn"
                          className="text-left text-sm !pb-0 !h-4 cursor-pointer"
                          onClick={() =>
                            setOpenTabs({
                              ...openTabs,
                              expandProblem: !openTabs.expandProblem,
                            })
                          }
                        >
                          See more{" "}
                          <ExpandMore
                            expand={openTabs.expandProblem}
                            aria-expanded={openTabs.expandProblem}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        </h2>
                      )}
                    </>
                  ) : (
                    <p className="summary-project w-full">No data found</p>
                  )}
                </div>
              </div>
              <div className="item-market">
                <div className="item-inner min-h-[180px]">
                  <div className="flex items-start justify-center mb-1">
                    <h2 className="pr-4">Key pain points</h2>
                  </div>
                  <div className="item-inner-logos">
                    <div className="flex items-start justify-center">
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectKeyPainPoint1?.icon}
                        text={tbProjectTabData?.projectKeyPainPoint1?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectKeyPainPoint2?.icon}
                        text={tbProjectTabData?.projectKeyPainPoint2?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectKeyPainPoint3?.icon}
                        text={tbProjectTabData?.projectKeyPainPoint3?.name}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-market">
                <div className="item-inner min-h-[180px]">
                  <div className="flex items-start mb-1 justify-center">
                    <h2 className="pr-4">Project themes</h2>
                  </div>
                  <div className="item-inner-logos">
                    <div className="flex items-start justify-center">
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectTheme1?.icon}
                        text={tbProjectTabData?.projectTheme1?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectTheme2?.icon}
                        text={tbProjectTabData?.projectTheme2?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectTheme3?.icon}
                        text={tbProjectTabData?.projectTheme3?.name}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="market-overview-place">
              <div className="item-market-2 overview-project">
                <div className="item-inner min-h-[180px]">
                  <h2 className="pr-4 text-left">PTC remit</h2>
                  {tbProjectTabData?.ptcRemit !== null ? (
                    <>
                      <p className="summary-project w-full">
                        {String(
                          tbProjectTabData?.ptcRemit || "No data found"
                        ).substring(
                          0,
                          openTabs.expandPtcRemit
                            ? tbProjectTabData?.ptcRemit.length
                            : 224
                        )}
                      </p>
                      {tbProjectTabData?.ptcRemit?.length > 224 && (
                        <h2
                          id="see-more-btn"
                          className="text-left text-sm !pb-0 !h-4 cursor-pointer"
                          onClick={() =>
                            setOpenTabs({
                              ...openTabs,
                              expandPtcRemit: !openTabs.expandPtcRemit,
                            })
                          }
                        >
                          See more{" "}
                          <ExpandMore
                            expand={openTabs.expandPtcRemit}
                            aria-expanded={openTabs.expandPtcRemit}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        </h2>
                      )}
                    </>
                  ) : (
                    <p className="summary-project w-full">No data found</p>
                  )}
                </div>
              </div>
              <div className="item-market-2 overview-project">
                <div className="item-inner min-h-[180px] bg-white border-[#C9CDD5]">
                  <div className="flex items-start justify-center">
                    <h2 className="pr-4">Project deliverables</h2>
                  </div>
                  <div className="item-inner-logos">
                    <div className="flex items-start justify-center flex-wrap md:flex-nowrap">
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectDeliverables1?.icon}
                        text={tbProjectTabData?.projectDeliverables1?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectDeliverables2?.icon}
                        text={tbProjectTabData?.projectDeliverables2?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectDeliverables3?.icon}
                        text={tbProjectTabData?.projectDeliverables3?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectDeliverables4?.icon}
                        text={tbProjectTabData?.projectDeliverables4?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectDeliverables5?.icon}
                        text={tbProjectTabData?.projectDeliverables5?.name}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="market-overview-place" id="budget-section">
              <div className="item-market-2 overview-project">
                <div className="item-inner min-h-[230px] bg-white border-[#C9CDD5]">
                  <h2 className="pr-4">Budget</h2>
                  {tbProjectTabData?.budget !== null ? (
                    <>
                      <Box className="w-full">
                        <div className="flex items-center justify-center relative">
                          <KeyboardArrowLeftRoundedIcon className="text-[#111D43] text-3xl font-bold -mr-5" />
                          <div className="border border-[#111D43] rounded-xl w-[100%]"></div>
                          <KeyboardArrowRightRoundedIcon className="text-[#111D43] text-3xl font-bold -ml-5" />
                          <p className="budget-label-1 absolute top-1 bg-[#009BE2] font-semibold  w-[160px] flex items-center justify-center rounded-[50px] h-6 text-white">
                            {numFormatter(tbProjectTabData?.budget)}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <h2> {investmentSize[0].label}</h2>
                          <h2>
                            {numFormatterProject(
                              Number(tbProjectTabData?.budget) * 2
                            )}
                          </h2>
                        </div>
                      </Box>
                      <div className="flex items-center justify-between mt-1">
                        <div className="bg-[#F7F7F7] w-[45%] px-4  pt-4 rounded-2xl">
                          <h2 className="text-left opacity-40 font-semibold">
                            Deadline
                          </h2>
                          <h2 className="opacity-45 text-left font-normal">
                            {moment(tbProjectTabData?.deadlineDate).format(
                              "MMMM YYYY"
                            )}
                          </h2>
                        </div>
                        <div className="bg-[#F7F7F7] w-[45%] rounded-2xl px-4 pt-4">
                          <h2 className="opacity-40 text-left font-semibold">
                            Asset type
                          </h2>
                          <h2 className="text-left font-normal">
                            {tbProjectTabData?.projectAssetType?.name}
                          </h2>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>No data found</p>
                  )}
                </div>
              </div>
              <div className="item-market-2 overview-project ">
                <div className="item-inner min-h-[230px]">
                  <div className="flex items-start justify-center">
                    <h2 className="pr-4">Buildings</h2>
                  </div>
                  {tbProjectTabData?.projectRegions?.length ? (
                    <div className="w-full">
                      <h2 className="text-left opacity-70 mt-4 mb-2">Region</h2>
                      <div className="flex items-center xs:flex-wrap sm:flex-wr xl:flex-nowrap">
                        <ul className="listing-item-place badge-item">
                          <>
                            {tbProjectTabData?.projectRegions?.length !== 0 &&
                              tbProjectTabData?.projectRegions?.map(
                                (item, idx) => (
                                  <Tooltip
                                    title={item?.tbRegions?.name}
                                    arrow
                                    key={idx}
                                  >
                                    <li>
                                      <img src={mapIcon} alt="Icon" />
                                      {item?.tbRegions?.name}
                                    </li>
                                  </Tooltip>
                                )
                              )}
                          </>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <p>No data found</p>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="market-overview-place justify-between">
              <div className="item-market-3 overview-project">
                <div className="item-inner p-6 bg-white border-[#C9CDD5]">
                  <h2 className="pr-4  mb-2 text-left">Key criteria</h2>

                  <div className="growth-info">
                    {tbProjectTabData?.projectAssesmentCriteria?.length ? (
                      <>
                        {tbProjectTabData?.projectAssesmentCriteria?.map(
                          (item, idx) => {
                            return (
                              <Fragment key={idx}>
                                {idx <= 3 ? (
                                  <div className="growth-item pb-4 business-modal">
                                    <h3>{item?.heading}</h3>
                                    <p>{item?.value}</p>
                                  </div>
                                ) : idx > 3 && openTabs.expandCriteria ? (
                                  <div
                                    className="growth-item business-modal"
                                    key={idx}
                                  >
                                    <h3>{item?.heading}</h3>
                                    <p>{item?.value}</p>
                                  </div>
                                ) : null}
                              </Fragment>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <p>No data found</p>
                    )}
                  </div>

                  {tbProjectTabData?.projectAssesmentCriteria?.length > 4 && (
                    <h2
                      id="see-more-btn"
                      className="text-left text-sm !pb-0 !h-4 flex items-center cursor-pointer"
                      onClick={() =>
                        setOpenTabs({
                          ...openTabs,
                          expandCriteria: !openTabs.expandCriteria,
                        })
                      }
                    >
                      See more{" "}
                      <ExpandMore
                        expand={openTabs.expandCriteria}
                        aria-expanded={openTabs.expandCriteria}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </h2>
                  )}
                </div>
              </div>
              <div className="item-market overview-project">
                <div className="item-inner-2 ">
                  <div className="flex items-start justify-center">
                    <h2 className="pr-4">Key users</h2>
                  </div>
                  <div className="item-inner-logos">
                    <div className="flex items-start justify-center gap-4">
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectKeyUser1?.icon}
                        text={tbProjectTabData?.projectKeyUser1?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectKeyUser2?.icon}
                        text={tbProjectTabData?.projectKeyUser2?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectKeyUser3?.icon}
                        text={tbProjectTabData?.projectKeyUser3?.name}
                      />
                    </div>
                  </div>
                </div>
                <div className="item-inner-2 ">
                  <div className="flex items-start justify-center">
                    <h2 className="pr-4">Key metrics</h2>
                  </div>
                  <div className="item-inner-logos">
                    <div className="flex items-start justify-center gap-4">
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectKeyMetric1?.icon}
                        text={tbProjectTabData?.projectKeyMetric1?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectKeyMetric2?.icon}
                        text={tbProjectTabData?.projectKeyMetric2?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectKeyMetric3?.icon}
                        text={tbProjectTabData?.projectKeyMetric3?.name}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <div className="market-overview-place flex-wrap">
            <div className="item-market-2 overview-project">
              <div className="item-inner bg-white text-left border-[#C9CDD5]">
                <h2 className="mb-2">Current technology</h2>
                {tbProjectTabData?.projectCurrentTechnology1 ? (
                  <>
                    <div className="flex border-b pb-2 border-[#C9CDD5]">
                      <div className="w-[30%]">
                        <h2 className="opacity-80 text-xs">Tech</h2>
                        <div className="flex flex-col justify-between h-[95%]">
                          <div className="flex items-center justify-center flex-col">
                            <img
                              src={
                                tbProjectTabData?.projectCurrentTechnology1
                                  ?.icon
                              }
                              alt=""
                              className="w-12 h-12"
                            />
                            <h2 className="text-xs mt-2 opacity-80 font-normal">
                              {
                                tbProjectTabData?.projectCurrentTechnology1
                                  ?.name
                              }
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="w-[70%]">
                        <h2 className="opacity-80 text-xs">
                          Associated issues
                        </h2>
                        <div className="flex flex-col justify-between h-[90%]">
                          <div>
                            <div className="growth-item mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.currentTech1KeyPoint1}
                              </p>
                            </div>
                            <div className="growth-item mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.currentTech1KeyPoint2}
                              </p>
                            </div>{" "}
                            <div className="growth-item mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.currentTech1KeyPoint3}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex border-b pb-2 border-[#C9CDD5] pt-6 ">
                      <div className="w-[30%]">
                        <div className="flex flex-col justify-between h-[95%]">
                          <div className="flex items-center justify-center flex-col">
                            <img
                              src={
                                tbProjectTabData?.projectCurrentTechnology2
                                  ?.icon
                              }
                              alt=""
                              className="w-12 h-12"
                            />
                            <h2 className="text-xs mt-2 opacity-80 font-normal">
                              {
                                tbProjectTabData?.projectCurrentTechnology2
                                  ?.name
                              }
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="w-[70%]">
                        <div className="flex flex-col justify-between h-[90%]">
                          <div>
                            <div className="growth-item mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.currentTech2KeyPoint1}
                              </p>
                            </div>
                            <div className="growth-item mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.currentTech2KeyPoint2}
                              </p>
                            </div>{" "}
                            <div className="growth-item mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.currentTech2KeyPoint3}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex pt-6 ">
                      <div className="w-[30%]">
                        <div className="flex flex-col justify-between h-[95%]">
                          <div className="flex items-center justify-center flex-col">
                            <img
                              src={
                                tbProjectTabData?.projectCurrentTechnology3
                                  ?.icon
                              }
                              alt=""
                              className="w-12 h-12"
                            />
                            <h2 className="text-xs mt-2 opacity-80 font-normal">
                              {
                                tbProjectTabData?.projectCurrentTechnology3
                                  ?.name
                              }
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="w-[70%]">
                        <div className="flex flex-col justify-between h-[90%]">
                          <div>
                            <div className="growth-item mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.currentTech3KeyPoint1}
                              </p>
                            </div>
                            <div className="growth-item mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.currentTech3KeyPoint2}
                              </p>
                            </div>{" "}
                            <div className="growth-item mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.currentTech3KeyPoint3}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <p>No data found</p>
                )}
              </div>
            </div>
            <div className="item-market-2 overview-project">
              <div className="item-inner bg-white text-left border-[#C9CDD5]">
                <h2 className="mb-2">Suggested technology types</h2>
                {tbProjectTabData?.projectSuggestedTechnology1 ? (
                  <>
                    <div className="flex border-b pb-2 border-[#C9CDD5]">
                      <div className="w-[30%]">
                        <h2 className="opacity-80 text-xs">Tech</h2>
                        <div className="flex flex-col justify-between h-[95%]">
                          <div className="flex items-center justify-center flex-col">
                            <img
                              src={
                                tbProjectTabData?.projectSuggestedTechnology1
                                  ?.icon
                              }
                              alt=""
                              className="w-12 h-12"
                            />
                            <h2 className="text-xs mt-2 opacity-80 font-normal">
                              {
                                tbProjectTabData?.projectSuggestedTechnology1
                                  ?.name
                              }
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="w-[70%]">
                        <h2 className="opacity-80 text-xs">
                          Associated issues
                        </h2>
                        <div className="flex flex-col justify-between h-[90%]">
                          <div>
                            <div className="growth-item-2 mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.suggestedTech1KeyPoint1}
                              </p>
                            </div>
                            <div className="growth-item-2 mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.suggestedTech1KeyPoint2}
                              </p>
                            </div>{" "}
                            <div className="growth-item-2 mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.suggestedTech1KeyPoint3}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex border-b pb-2 border-[#C9CDD5] pt-6 ">
                      <div className="w-[30%]">
                        <div className="flex flex-col justify-between h-[95%]">
                          <div className="flex items-center justify-center flex-col">
                            <img
                              src={
                                tbProjectTabData?.projectSuggestedTechnology2
                                  ?.icon
                              }
                              alt=""
                              className="w-12 h-12"
                            />
                            <h2 className="text-xs mt-2 opacity-80 font-normal">
                              {
                                tbProjectTabData?.projectSuggestedTechnology2
                                  ?.name
                              }
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="w-[70%]">
                        <div className="flex flex-col justify-between h-[90%]">
                          <div>
                            <div className="growth-item-2 mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.suggestedTech2KeyPoint1}
                              </p>
                            </div>
                            <div className="growth-item-2 mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.suggestedTech2KeyPoint2}
                              </p>
                            </div>{" "}
                            <div className="growth-item-2 mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.suggestedTech2KeyPoint3}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex pt-6 ">
                      <div className="w-[30%]">
                        <div className="flex flex-col justify-between h-[95%]">
                          <div className="flex items-center justify-center flex-col">
                            <img
                              src={
                                tbProjectTabData?.projectSuggestedTechnology3
                                  ?.icon
                              }
                              alt=""
                              className="w-12 h-12"
                            />
                            <h2 className="text-xs mt-2 opacity-80 font-normal">
                              {
                                tbProjectTabData?.projectSuggestedTechnology3
                                  ?.name
                              }
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="w-[70%]">
                        <div className="flex flex-col justify-between h-[90%]">
                          <div>
                            <div className="growth-item-2 mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.suggestedTech3KeyPoint1}
                              </p>
                            </div>
                            <div className="growth-item-2 mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.suggestedTech3KeyPoint2}
                              </p>
                            </div>{" "}
                            <div className="growth-item-2 mb-2">
                              <p className="text-xs text-[#70778E] leading-[18px]">
                                {tbProjectTabData?.suggestedTech3KeyPoint3}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <p>No data found</p>
                )}
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <>
            <div className="market-overview-place justify-between">
              <div className="item-market-3 overview-project">
                <div className="item-inner p-6 bg-white border-[#C9CDD5]">
                  <h2 className="pr-4 mb-2 text-left">System Integration</h2>
                  <div className="growth-info">
                    {tbProjectTabData?.projectSystemIntegration?.length ? (
                      <>
                        {tbProjectTabData?.projectSystemIntegration?.map(
                          (item, idx) => {
                            return (
                              <Fragment key={idx}>
                                {idx <= 3 ? (
                                  <div
                                    className="growth-item pb-4 business-modal"
                                    key={idx}
                                  >
                                    <h3>{item?.projectIntegrationKey?.name}</h3>
                                    <p>{item?.value}</p>
                                  </div>
                                ) : idx > 3 && openTabs.expandedIntegration ? (
                                  <div
                                    className="growth-item business-modal"
                                    key={idx}
                                  >
                                    <h3>{item?.projectIntegrationKey?.name}</h3>
                                    <p>{item?.value}</p>
                                  </div>
                                ) : null}
                              </Fragment>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <p className="text-left">No data found</p>
                    )}
                  </div>
                  {tbProjectTabData?.projectSystemIntegration?.length > 4 && (
                    <h2
                      id="see-more-btn"
                      className="text-left text-sm !pb-0 !h-4 flex items-center cursor-pointer"
                      onClick={() =>
                        setOpenTabs({
                          ...openTabs,
                          expandedIntegration: !openTabs.expandedIntegration,
                        })
                      }
                    >
                      See more{" "}
                      <ExpandMore
                        expand={openTabs.expandedIntegration}
                        aria-expanded={openTabs.expandedIntegration}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </h2>
                  )}
                </div>
              </div>
              <div className="item-market overview-project">
                <div className="item-inner-2">
                  <div className="flex items-start justify-center">
                    <h2 className="pr-4">Key issues</h2>
                  </div>
                  <div className="item-inner-logos">
                    <div className="flex items-start justify-center gap-4">
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectKeyIssue1?.icon}
                        text={tbProjectTabData?.projectKeyIssue1?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectKeyIssue2?.icon}
                        text={tbProjectTabData?.projectKeyIssue2?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectKeyIssue3?.icon}
                        text={tbProjectTabData?.projectKeyIssue3?.name}
                      />
                    </div>
                  </div>
                </div>
                <div className="item-inner-2">
                  <div className="flex items-start justify-center">
                    <h2 className="pr-4">Risks</h2>
                  </div>
                  <div className="item-inner-logos">
                    <div className="flex items-start justify-center gap-4">
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectRisk1?.icon}
                        text={tbProjectTabData?.projectRisk1?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectRisk2?.icon}
                        text={tbProjectTabData?.projectRisk2?.name}
                      />
                      <KeySpanLogo
                        logo={tbProjectTabData?.projectRisk3?.icon}
                        text={tbProjectTabData?.projectRisk3?.name}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 5:
        return (
          <>
            <div className="market-overview-place justify-between">
              <div className="item-market-3 col-6 ">
                <div className="item-inner bg-white border-[#C9CDD5]">
                  <h2 className="pr-4 text-left">Timeline</h2>
                  {tbProjectTabData?.projectTimeline?.length !== 0 ? (
                    <div className="flex justify-start overflow-auto">
                      <CustomizedTimeline
                        data={tbProjectTabData?.projectTimeline}
                      />
                    </div>
                  ) : (
                    <p className="text-left">No data found</p>
                  )}
                </div>
              </div>
            </div>
            <div className="market-overview-place justify-between">
              <div className="item-market-3 col-6 ">
                <div className="item-inner p-6 bg-white border-[#C9CDD5]">
                  <h2 className="pr-4 mb-2 text-left">
                    Decision making process
                  </h2>
                  <div className="growth-info">
                    {tbProjectTabData?.projectDecisionProcess?.length ? (
                      <>
                        {tbProjectTabData?.projectDecisionProcess?.map(
                          (item, idx) => {
                            return (
                              <Fragment key={idx}>
                                {idx <= 3 ? (
                                  <div
                                    className="growth-item pb-4 business-modal"
                                    key={idx}
                                  >
                                    <h3>{item?.heading}</h3>
                                    <p>{item?.value}</p>
                                  </div>
                                ) : idx > 3 &&
                                  openTabs.expandedDecisionMaking ? (
                                  <div
                                    className="growth-item business-modal"
                                    key={idx}
                                  >
                                    <h3>{item?.heading}</h3>
                                    <p>{item?.value}</p>
                                  </div>
                                ) : null}
                              </Fragment>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <p className="text-left">No data found</p>
                    )}
                  </div>
                  {tbProjectTabData?.projectDecisionProcess?.length > 4 && (
                    <h2
                      id="see-more-btn"
                      className="text-left text-sm !pb-0 !h-4 flex items-center cursor-pointer"
                      onClick={() =>
                        setOpenTabs({
                          ...openTabs,
                          expandedDecisionMaking:
                            !openTabs.expandedDecisionMaking,
                        })
                      }
                    >
                      See more{" "}
                      <ExpandMore
                        expand={openTabs.expandedDecisionMaking}
                        aria-expanded={openTabs.expandedDecisionMaking}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </h2>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 6:
        return (
          <div className="flex gap-2 flex-wrap">
            {tbProjectTabData?.projectAttachment?.length ? (
              <>
                {tbProjectTabData?.projectAttachment?.map((item, idx) => (
                  <div
                    key={idx}
                    className="border border-[#C9CDD5] rounded-3xl px-6 py-4 min-w-[318px] h-[96px] flex items-center gap-4"
                  >
                    <div className="w-16 h-16 bg-[#F7F7F7] rounded-2xl flex items-center justify-center">
                      <img src={fileIcon} alt="" className="h-8 w-8" />
                    </div>
                    <h2 className="text-[#111D43] font-[Poppins] min-w-[130px] max-w-[260px] leading-5 text-md">
                      {extractFilenameFromURL(item?.link)}
                    </h2>
                    <img
                      src={downloadIcon}
                      alt=""
                      onClick={() => window.open(item?.link, "_blank")}
                      className="h-6 w-6 cursor-pointer"
                    />
                  </div>
                ))}
              </>
            ) : (
              <div className=" border w-full rounded-xl p-4 border-[#C9CDD5]">
                <p className="text-left">No data found</p>
              </div>
            )}
          </div>
        );
      default:
        break;
    }
  };

  const scrollToDivById = (divId, top) => {
    const div = document.getElementById(divId);
    if (top && div) {
      div.scrollIntoView();
      return;
    }
    if (div) {
      div.scrollIntoView({ block: "center" });
    }
  };

  return (
    <div className="project-tab-place relative" id="Project-tab-place">
      {tbProjectTabData !== null ? (
        <>
          <div className="header-1 w-full flex justify-between flex-wrap px-4 py-2 md:px-12 ">
            <div className="nav-top flex gap-4 items-center flex-wrap">
              <div className="flex items-center gap-2">
                <div className="overflow-hidden">
                  <CompanyLogo
                    companyLogo={
                      tbProjectTabData?.companyOtherDetails?.companyLogo
                    }
                    name={
                      tbProjectTabData?.companyOtherDetails?.tbCompany?.name
                    }
                  />
                </div>
                <div className="basis-[65%] md:basis-[80%]">
                  <h2 className=" !max-w-[400px]">
                    {tbProjectTabData?.companyOtherDetails?.tbCompany?.name || "-"}
                  </h2>
                  <div
                    id="project-countries"
                    className=" h-[40px]  !max-w-[400px] overflow-x-hidden"
                  >
                    <ul className="listing-item-place badge-item">
                      <Tooltip title={"Country(HQ)"} arrow>
                        <li>
                          <img src={mapIcon} alt="Icon" />
                          {tbProjectTabData?.companyOtherDetails?.country?.name || "-"}
                        </li>
                      </Tooltip>
                      <Tooltip title={"Country"} arrow>
                        <li>
                          <img src={mapIcon} alt="Icon" />
                          {
                            tbProjectTabData?.companyOtherDetails
                              ?.otherCountries[0]?.tbCountries?.name
                          }
                        </li>
                      </Tooltip>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div className="flex gap-4 items-center">
                  {tbProjectTabData?.companyOtherDetails?.ownerFirstName !==
                    null && (
                    <>
                      <div className="w-16 h-16 flex bg-[#E6E7E8] items-center rounded-md justify-center">
                        <img src={userBg} alt="" />
                      </div>
                      <div>
                        <h2 className="text-xs lg:text-sm">
                          {
                            tbProjectTabData?.companyOtherDetails
                              ?.ownerFirstName
                          }
                          {" "}{tbProjectTabData?.companyOtherDetails?.ownerLastName}
                        </h2>
                        <p className="text-xs lg:text-sm text-[#111D43]">Project Lead</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div>
              <PTButton
                label="Download summary"
                startIcon={<img src={downloadIcon} alt="" />}
                className="normal-case fill-gray btn"
                handleOnClickEvent={onDownloadSummary}
                id="download-summary-btn"
              />
            </div>
          </div>
          <div className="project-tabs  px-4 md:px-14 sticky top-0 z-10 bg-white">
            {projectPageTabs?.map((item, idx) => (
              <h2
                className="text-sm font-semibold cursor-pointer"
                key={idx}
                onClick={() => scrollToDivById(`#${item}`)}
              >
                {item}
              </h2>
            ))}
          </div>
          <div className="bg-white">
            {projectPageTabs?.map((item, idx) => (
              <Accordion
                key={`${idx}`}
                id={`${item}`}
                elevation={0}
                disableGutters
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
                className="project-content px-4 md:px-8"
                expanded={openTabs[idx + 1] === true}
                onChange={() =>
                  setOpenTabs({
                    ...openTabs,
                    [`${idx + 1}`]: !openTabs[idx + 1],
                  })
                }
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      id="see-more-btn"
                      className="text-[#111D43]"
                    />
                  }
                  aria-controls={`#${item}`}
                  id={`#${item}`}
                  sx={{ border: "none" }}
                >
                  <h2 className="lg:text-2xl left-bordered-text orange-border">
                    {item}
                  </h2>
                </AccordionSummary>
                <AccordionDetails sx={{ border: "none" }}>
                  <div id="project-content">{content(idx)}</div>
                </AccordionDetails>
              </Accordion>
            ))}
            <h2
              id="back-to-top"
              className="text-md ml-14 py-10 bg-white flex items-center cursor-pointer w-fit"
              onClick={() => scrollToDivById("Project-tab-place", true)}
            >
              Back to Top <NorthIcon className="ml-2" />
            </h2>
          </div>
        </>
      ) : (
        <>
          {!isLoading && (
            <div className="p-[15px] font-semibold opacity-40 proptech-count-wrapper">
              <span className="pb-1 proptech-count">No project found</span>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ProjectTab;
