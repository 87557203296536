import { Navigate } from "react-router-dom";
import { getTbAccessToken } from "../../../utils/session";
import { useEffect } from "react";
import { getPlansData } from "../../utils/middleware/permissions";

// Access of Routes For logged in user
const PrivateRoute = ({ children }) => {
  const isLogin = getTbAccessToken();

  useEffect(() => {
    getPlansData();
  }, []);

  return (
    <>
      {isLogin ? children : <Navigate to="/login" />}
    </>
  );
};
export default PrivateRoute;