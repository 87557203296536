export const chartData = [
  "evolvingAssets",
  "userExperience",
  "decisionMaking",
  "smarterBuilds",
  "connectivity",
  "trackRecord"
];

export const chartLabels = [
  "Evolving assets",
  ["User", "experience"],
  ["Decision", "making"],
  "Smarter builds",
  "Connectivity",
  ["Track", "Record"]
];

export const marketChartLabels = [
  ["Unique","Offering"],
  ["Deal","Closing"],
  ["Integration +"," Operability"],
  ["Use case + ", "User Count"],
  ["Payback", " visibility"],
  ["Ease of setup + ","Use"],
];

export const numbersChartLabels = [
  ["Market Leader","Customers"],
  "New customers",
  ["Enterprise","RFP experience"],
  ["Multi-country / ","language customers"],
  ["Repeat", "Customers"],
  ["Tech advanced", "organization"],
];

export const designReviewChartLabels = [
  "Architecture",
  "Power",
  "Processing",
  "Communication",
  "Search",
  "Storage",
]

export const deployAndUseChartLabels = [
  ["Customer", "Support"],
  ["Flexible","architecture"],
  "Scalability",
  "Tech agnostic",
  ["Customisable","functionality"],
  ["New features,", "roadmap"],
]