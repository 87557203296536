export default function publicProptechUser(
  initialState = {
    isVerified: false,
    id: null,
  },
  action
) {
  switch (action.type) {
    case "SET_ID":
      return {
        ...initialState,
        id: action.payload,
      };

    case "SET_IS_VERIFIED":
      return {
        ...initialState,
        isVerified: action.payload,
      };

    case "USER_RESET":
      return {
        ...initialState,
        isVerified: false,
        id: null,
      };

    default:
      return initialState;
  }
}
