import moment from 'moment';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Menu, MenuItem } from '@mui/material';
import { NotificationAnchorOrigin, NotificationModalStyle, NotificationTransformOrigin } from '../../constants/General';
import { useNavigate } from 'react-router-dom';
import { Actions } from '../../store/actions';
import { dispatch } from '../../utils/utility/store';
import { NOTIFICATION } from '../../constants/General/notification';

export default function NotificationModal(props) {
  // Props
  const { open, anchor, loading, notifications, handleClose, readNotification, clearNotification } = props

  const navigate = useNavigate()

  // Handle redirection of notifications
  const redirectNotification = (id, redirect, proptechId, type) => {
    redirect && readNotification(id)
    handleClose()
    if (type === 1 || type === 3) {
      dispatch(Actions.TBProptechDetails.TBProptechDetailsReset);
      navigate(`/proptech/${proptechId}`)
    }
  };

  return (
    <Menu
      anchorEl={anchor}
      id="notification-menu"
      className="dropdown_menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={NotificationModalStyle}
      transformOrigin={NotificationTransformOrigin}
      anchorOrigin={NotificationAnchorOrigin}
    >
      <div className='flex justify-between items-center pr-3 pb-2'>
        <h2>{NOTIFICATION.notifications}</h2>
        <div className={`font-semibold text-xs text-primary cursor-pointer ${notifications?.length === 0 && 'pointer-events-none opacity-40'}`} title="Clear all" onClick={clearNotification}>Clear all</div>
      </div>
      <div className='notification-modal'>
        {loading ?
          <div className='notification-loader'>
            <CircularProgress />
          </div>
          : notifications?.length !== 0 && notifications.map(notification => {
            return <MenuItem
              className={`user-info-place ${!notification.notificationsLog?.length && 'new-notification'}`}
              onClick={() => redirectNotification(notification?.id, !notification.notificationsLog?.length, notification.data.proptechCompanyDetailsId, notification.type)}>
              <div className='user-data'>
                <p>{notification.text}</p>
                <span>{moment(notification.createdAt).fromNow()}</span>
              </div>
            </MenuItem>
          })}
        {!loading && notifications?.length !== 0 ?
          <span className='thats-all'>{NOTIFICATION.thatsAll}</span> :
          <span className='thats-all'>{NOTIFICATION.noNotification}</span>
        }
      </div>
      <span className='text-xs ml-6'>Note: All times is in UTC Timezone</span>
    </Menu>
  )
}
