import { Navigate } from 'react-router-dom';
import { getTbAccessToken } from '../../../utils/session';

// For public user
const PublicRoute = ({ children }) => {
    const isLogin = getTbAccessToken()

    return (
        <>
          {!isLogin ? children : <Navigate to='/dashboard' />}
        </>
      );
}
export default PublicRoute;
