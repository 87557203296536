import { TB_API } from "../../../../utils/utility/API-call";
import { TB_REGIONS_API, TB_ACTIVEUSERSLIST_API, TB_INVESTMENT_STAGES_API } from "../../../../constants/APIs";
import { handleError } from "../../../../utils/utility/snackbar";

export const getRegionList = () => {
  return TB_API().get(TB_REGIONS_API)
    .then(res => {
      if (res.data.data) {
        return res.data.data
      }
      if (res?.data?.code !== 200) {
        handleError(res?.data);
      }
    })
    .catch((error) => {
      handleError(error)
    })
};

export const getInvestmentStagesList = () => {
  return TB_API().get(TB_INVESTMENT_STAGES_API)
    .then(res => {
      if (res.data.data) {
        return res.data.data
      }
      if (res?.data?.code !== 200) {
        handleError(res?.data);
      }
    })
    .catch((error) => {
      handleError(error)
    })
};

export const getActiveUsers = () => {
  return TB_API().get(TB_ACTIVEUSERSLIST_API)
    .then(res => {
      if (res?.data && res?.data?.code === 200) {
        return res?.data?.data
      }
      handleError(res?.data)
    })
    .catch((error) => {
    })
}