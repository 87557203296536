export default function tbProptechs(
    initialState = {
      tbProptechs: null,
      tbWatchList: null,
      tbWatchListPage: 0,
      tbWatchListPageSize: 10,
      tbMasterData: null,
      tbActiveUsers: [],
      showModal: false,
      filter: null,
      orderBy: 'createdAt',
      order: 'DESC',
      page: 0,
      size: 10,
      isLoading: false,
      activeTabId: 0,
      tbProjectTabData: null
    },
    action
  ) {
    switch (action.type) {
      case 'TB_SHOW_MODAL':
        return {
          ...initialState,
          showModal: action.payload,
        };
  
      case 'TB_PROPTECHS_LIST_SET':
        return {
          ...initialState,
          tbProptechs: action.payload,
        };
  
      case 'TB_WATCHLIST_SET':
        return {
          ...initialState,
          tbWatchList: action.payload,
        };
  
      case 'TB_WATCHLIST_PAGE_SET':
        return {
          ...initialState,
          tbWatchListPage: action.payload,
        };
  
      case 'TB_WATCHLIST_PAGESIZE_SET':
        return {
          ...initialState,
          tbWatchListPageSize: action.payload,
        };
  
      case 'TB_SET_ORDER':
        return {
          ...initialState,
          order: action.payload,
        }
  
      case 'TB_SET_FILTER_OPTION':
        return {
          ...initialState,
          filter: action.payload,
        }
  
      case 'TB_SET_ORDER_BY':
        return {
          ...initialState,
          orderBy: action.payload,
        }
  
      case 'TB_SET_PAGE':
        return {
          ...initialState,
          page: action.payload
        }
  
      case 'TB_SET_PAGESIZE':
        return {
          ...initialState,
          size: action.payload
        }
  
      case 'TB_SET_LOADING':
        return {
          ...initialState,
          isLoading: action.payload
        }
  
      case 'TB_SET_MASTERDATA':
        return {
          ...initialState,
          tbMasterData: action.payload
        }
  
      case 'TB_SET_ACTIVEUSERS':
        return {
          ...initialState,
          tbActiveUsers: action.payload
        }
  
      case 'TB_SET_ACTIVETAB':
        return {
          ...initialState,
          activeTabId: action.payload
        }
      
      case 'TB_PROJECT_TAB_DATA':
        return {
          ...initialState,
          tbProjectTabData: action.payload
        }
  
      case 'TB_RESET_LIST':
        return {
          ...initialState,
          tbProptechs: null,
          tbWatchList: null,
        }
  
      case 'TB_PROPTECHS_RESET':
        return {
          ...initialState,
          tbProptechs: null,
          tbWatchList: null,
          tbWatchListPage: 0,
          tbWatchListPageSize: 10,
          tbMasterData: null,
          tbActiveUsers: [],
          showModal: false,
          filter: null,
          orderBy: 'createdAt',
          order: 'DESC',
          page: 1,
          size: 10,
          isLoading: false,
          activeTabId: 0,
          tbProjectTabData: null
        };
  
      default:
        return initialState;
    }
  }
  